import classNames from 'classnames';
// import { Splitter } from 'components/Splitter/Splitter';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NonAuthRoutes } from 'routes/routes';
import st from './Hero.module.scss';

const Hero = () => {
    const intl = useIntl();
    const { mobileIs } = useSelector(({ UIStore }) => UIStore);

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                <div className="row">
                    <div className="col-2 col-md-3 col-lg-4 col-xl-6"></div>
                    <div className="col-3 col-md-4 col-lg-5 col-xl-4">
                        <div className={st.title}>
                            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.hero.title.1.1' }) }} />
                            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.hero.title.1.2' }) }} />
                        </div>
                    </div>
                    <div className="col-1 col-md-1 col-lg-3 col-xl-5"></div>

                    <div className="col-1 col-md-2 col-lg-2 col-xl-4"></div>
                    <div className="col-3 col-md-4 col-lg-6 col-xl-4">
                        <div className={classNames(st.title, st.title_2)}>
                            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.hero.title.2.1' }) }} />
                            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.hero.title.2.2' }) }} />
                        </div>
                    </div>
                    <div className="col-2 col-md-2 col-lg-4 col-xl-7"></div>

                    <div className="col col-md"></div>
                    <div className="col-md-2 col-lg-3 col-xl-3">
                        <div className={st.text}>
                            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                {intl.formatMessage({ id: 'home.hero.text' })}
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-md-block col-md-2 col-lg-2 col-xl-2"></div>
                </div>

                {!mobileIs && (
                    <Link to={NonAuthRoutes.work} className={st.work} data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="300">
                        {intl.formatMessage({ id: 'home.hero.work' })}
                    </Link>
                )}

                <div className={st.bottom}>
                    <div className="row">
                        {!mobileIs && (
                            <>
                                <div className="col-md-2 col-lg-3 col-xl-2">
                                    <div className={st.scroll} data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="300" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.hero.scroll' }) }} />
                                </div>

                                <div className="col-md-2 col-lg-1 col-xl-4"></div>
                            </>
                        )}
                        <div className="col-3 col-md-2 col-lg-3 col-xl-2">
                            <div className={st.social} data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="300">
                                <a href="https://t.me/BrandMyBrandBot" target="_blank" className={st.social__link} rel="noreferrer">
                                    {intl.formatMessage({ id: 'home.hero.telegram' })}
                                </a>
                                ,{' '}
                                <a href="https://wa.me/+436641333558" target="_blank" className={st.social__link} rel="noreferrer">
                                    {intl.formatMessage({ id: 'home.hero.whatsapp' })}
                                </a>
                                <span className={st.social__text}>{intl.formatMessage({ id: 'home.hero.talk' })}</span>
                            </div>
                        </div>

                        <div className="d-none d-lg-block col-lg-1 col-xl-2"></div>
                        <div className="col col-xl-2">
                            <div className={st.contact} data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="300">
                                <a href="mailto:hello@brandmybrand.io" target="_blank" className={st.contact__link} rel="noreferrer">
                                    hello@brandmybrand.io
                                </a>
                                <span className={st.contact__text}>{intl.formatMessage({ id: 'home.hero.connect' })}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
