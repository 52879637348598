// @ts-nocheck
import React from 'react';
import { useIntl } from 'react-intl';
import st from './Work.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Work = () => {
    const intl = useIntl();
    const { tabletIs } = useSelector(({ UIStore }) => UIStore);

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                <div className={classNames('row', st.top)}>
                    <div className="col-2 col-md-2 col-lg-2 col-xl-1">
                        <h2 className={st.subtitle}>{intl.formatMessage({ id: 'works.works.title' })}</h2>
                    </div>
                    <div className="d-none d-xl-block col-xl-1"></div>
                    <div className="col-2 col-md-2 col-lg-2 col-xl-1">
                        <div className={st.link}>{intl.formatMessage({ id: 'works.works.filter' })}</div>
                    </div>
                    <div className={st.dot}></div>
                </div>
                <p className={st.work}>{intl.formatMessage({ id: 'works.works.count' })}</p>
                <div className={st.body}>
                    <Link to={`/work/vipsfirst`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.vipsfirst.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.vipsfirst.top.subtitle' }) }} />
                            {/*<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.vipsfirst.credits.location' }) }} />*/}
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('../../../Work/images/vipsfirst/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.vipsfirst.top.title' })}
                            />
                        </div>
                    </Link>
                    <Link to={`/work/k2`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.k2.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.k2.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require(`../../../Work/images/k2/hero.jpg`)}
                                alt={intl.formatMessage({ id: 'works.k2.top.title' })}
                            />
                        </div>
                    </Link>
                    <Link to={`/work/fadorealestate`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.fadorealestate.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.fadorealestate.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('../../../Work/images/fadorealestate/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.fadorealestate.top.title' })}
                            />
                        </div>
                    </Link>
                    <Link to={`/work/arthromed`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.arthromed.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.arthromed.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require(`../../../Work/images/arthromed/hero.jpg`)}
                                alt={intl.formatMessage({ id: 'works.arthromed.top.title' })}
                            />
                        </div>
                    </Link>

                    {/*{!tabletIs && (
                        <div className={st.card}>
                            <div className={st.cyrcle}></div>
                        </div>
                    )}*/}

                    <Link to={`/work/armandiart`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.armandiart.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.armandiart.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('../../../Work/images/armandiart/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.armandiart.top.title' })}
                            />
                        </div>
                    </Link>

                    <Link to={`/work/qumar`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.qumar.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.qumar.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('../../../Work/images/qumar/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.qumar.top.title' })}
                            />
                        </div>
                    </Link>

                    <Link to={`/work/adbos`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.adbos.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.adbos.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require(`../../../Work/images/adbos/hero.jpg`)}
                                alt={intl.formatMessage({ id: 'works.adbos.top.title' })}
                            />
                        </div>
                    </Link>
                    <Link to={`/work/beautytown`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.beautytown.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.beautytown.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('../../../Work/images/beautytown/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.beautytown.top.title' })}
                            />
                        </div>
                    </Link>
                    {!tabletIs && (
                        <div className={st.card}>
                            <div className={st['dot-2']}></div>
                            <h2 className={st.text}>
                                <span className={st.text_1} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.cases.title' }) }} />
                                <span className={st.text_2} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.cases.title2' }) }} />
                                <Link to={`/`} className={st.text_3} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.cases.title3' }) }} />
                            </h2>
                        </div>
                    )}

                    <Link to={`/work/fsexpert`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.fsexpert.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.fsexpert.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('../../../Work/images/fsexpert/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.fsexpert.top.title' })}
                            />
                        </div>
                    </Link>
                    <Link to={`/work/deinarzt`} className={classNames('col-xl-6', st.card, st.card_3)}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.deinarzt.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.deinarzt.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require(`../../../Work/images/deinarzt/hero.jpg`)}
                                alt={intl.formatMessage({ id: 'works.deinarzt.top.title' })}
                            />
                        </div>
                    </Link>
                    <Link to={`/work/arabiandigitalsolutions`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.arabiandigitalsolutions.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.arabiandigitalsolutions.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('../../../Work/images/arabiandigitalsolutions/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.arabiandigitalsolutions.top.title' })}
                            />
                        </div>
                    </Link>

                    {/*{!tabletIs && (
                        <div className={st.card}>
                            <div className={st.cube}></div>
                        </div>
                    )}*/}

                    <Link to={`/work/alpine`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.alpine.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.alpine.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('../../../Work/images/alpine/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.alpine.top.title' })}
                            />
                        </div>
                    </Link>

                    <Link to={`/work/naschmarktdeli`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.naschmarktdeli.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.naschmarktdeli.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('../../../Work/images/naschmarktdeli/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.naschmarktdeli.top.title' })}
                            />
                        </div>
                    </Link>

                    <Link to={`/work/logofolio-1`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.logofolio-1.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.logofolio-1.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('../../../Work/images/logofolio-1/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.logofolio-1.top.title' })}
                            />
                        </div>
                    </Link>

                </div>
            </div>
        </section>
    );
};

export default Work;
