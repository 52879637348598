// @ts-nocheck
const initialState = {
    items: [],
    isLoading: false,
    error: null,
};

export function history(state = initialState, action) {
    switch (action.type) {
        case 'SET_HISTORY':
            return {
                ...state,
                items: [
                    ...action.payload.items,
                    ...state.items,
                ],
            };

        case 'UPDATE_HISTORY':
            state.items.map(obj => {
                if (obj.id === action.payload.id) {
                    obj.active_status = action.payload.active_status;

                    if (action.payload.seconds_track) {
                        obj.seconds_track = action.payload.seconds_track;
                    }

                    if (action.payload.count_state_change) {
                        obj.count_state_change = action.payload.count_state_change;
                    }
                }

                return obj;
            })

            return {
                ...state,
                items: [
                    ...state.items,
                ],
            };

        default:
            return state;
    }
}
