// @ts-nocheck
import React from 'react';
import st from './getInTouch.module.scss';

import Header from 'components/Header/Header';
import Hero from './components/Hero/Hero';
import Message from './components/Message/Message';
import { useSelector } from 'react-redux';

export const GetInTouchPage = () => {
    const { tabletIs } = useSelector(({ UIStore }) => UIStore);

    return (
        <>
            <Header />

            <main className={st.main}>
                {!tabletIs ? (
                    <div className="col-6 col-lg-8 col-xl-10">
                        <Hero />
                    </div>
                ) : (
                    <Hero />
                )}
                <Message />
            </main>
        </>
    );
};
