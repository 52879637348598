import st from './style.module.scss';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ links, classList }) => {
    return (
        <div className={classNames(st.breadcrumbs, classList)}>
            {links.map((item, index) => {
                if (item.link) {
                    return (
                        <Fragment key={index}>
                            <Link to={item.link} className={st.link}>
                                {item.name}
                            </Link>
                            <span className={st.slash}>/</span>
                        </Fragment>
                    );
                }

                return (
                    <span key={index} className={classNames(st.link, st.link_active)}>
                        {item.name}
                    </span>
                );
            })}
        </div>
    );
};

export default Breadcrumbs;
