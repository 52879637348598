// @ts-nocheck
import React from 'react';
import st from './Service.module.scss';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Service from './components/Service/Service';
import Work from 'pages/Home/components/Work/Work';
import Body from './components/Body/Body';
import Testimonials from 'pages/Services/components/Testimonials/Testimonials';

export const ServicePage = () => {
    return (
        <>
            <Header />

            <main className={st.main}>
                <Hero />
                <Service />
                <Body />
                <Work />
                <Testimonials />
            </main>

            <Footer bgColor="#31333F" />
        </>
    );
};
