// @ts-nocheck
import React from 'react';
import st from './blogs.module.scss';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

export const BlogsPage = () => {
    const intl = useIntl();
    const { mobileIs } = useSelector(({ UIStore }) => UIStore);

    return (
        <>
            <Header />

            <main className={st.main}>
                <section className={st.hero}>
                    <div className={st.container}>
                        <div className="row">
                            {!mobileIs && <div className="col-2 col-md-3 col-lg-4 col-xl-6"></div>}
                            <div className="col-6 col-md-5 col-lg-8 col-xl-9">
                                <h1 className={st.title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `blog.title` }) }} />
                            </div>
                        </div>
                    </div>
                </section>

                <section className={st.list}>
                    <div className={st.container}>
                        <div className="row">
                            <div className="col-6 col-md-4">
                                <Link to="/blog/1" className={st.list__item}>
                                    <div className={st.list__image}>
                                        {<img src="https://placehold.co/940x600" alt="" />}
                                    </div>
                                    <div className={st.list__content}>
                                        <p className={st.list__date} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blog.list.item1.date' }) }} />
                                        <h4 className={st.list__title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blog.list.item1.title' }) }} />
                                    </div>
                                </Link>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className={st.list__item}>
                                    <div className={st.list__image}>
                                        {<img src="https://placehold.co/940x600" alt="" />}
                                    </div>
                                    <div className={st.list__content}>
                                        <p className={st.list__date} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blog.list.item2.date' }) }} />
                                        <h4 className={st.list__title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blog.list.item2.title' }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className={st.list__item}>
                                    <div className={st.list__image}>
                                        {<img src="https://placehold.co/940x600" alt="" />}
                                    </div>
                                    <div className={st.list__content}>
                                        <p className={st.list__date} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blog.list.item3.date' }) }} />
                                        <h4 className={st.list__title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blog.list.item3.title' }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className={st.list__item}>
                                    <div className={st.list__image}>
                                        {<img src="https://placehold.co/940x1212" alt="" />}
                                    </div>
                                    <div className={st.list__content}>
                                        <p className={st.list__date} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blog.list.item4.date' }) }} />
                                        <h4 className={st.list__title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blog.list.item4.title' }) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer bgColor="#31333F" />
        </>
    );
};
