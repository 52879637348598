import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import style from './style.module.scss';
import React from 'react';

const DrawLine = () => {
    const canvas = useRef(null);
    const dpi = window.devicePixelRatio;
    const { tabletIs, mobileIs, theme } = useSelector(({ UIStore }) => UIStore);

    let tails = []; // Массив точек хвоста
    let context = null;
    const tailMaxLength = 0; // Максимальная длина хвоста
    let tailLength = 0; // Текущая длина хвоста
    const tailSpeed = 0.1; // Скорость изменения длины хвоста

    function addDotsline(event) {
        let point = {
            x: event.clientX,
            y: event.clientY,
        };

        tails.push(point);
    }

    function fixDpi(canvasEl) {
        // создаем объект стиля, который возвращает ширину и высоту
        const style = {
            height: +getComputedStyle(canvasEl).getPropertyValue('height').slice(0, -2),
            width: +getComputedStyle(canvasEl).getPropertyValue('width').slice(0, -2),
        };

        // установите правильные атрибуты для кристально чистого изображения!
        canvasEl.setAttribute('width', style.width * dpi);
        canvasEl.setAttribute('height', style.height * dpi);
    }

    function drawLine() {
        if (context) {
            context.clearRect(0, 0, canvas.current.width, canvas.current.height);

            // Рассчитываем длину хвоста на основе текущей длины и скорости
            tailLength += (tailMaxLength - tailLength) * tailSpeed;

            // Ограничиваем длину хвоста
            if (tails.length >= 100) {
                while (tails.length >= 100)
                    if (tails.length > tailLength) {
                        tails.shift(); // Удаляем первую точку хвоста
                    }
            } else {
                // Ограничиваем длину хвоста
                if (tails.length > tailLength) {
                    setTimeout(() => {
                        tails.shift(); // Удаляем первую точку хвоста
                    }, 300);
                }
            }

            if (tails.length > 0) {
                fixDpi(canvas.current);
                // Рисуем линию хвоста
                context.lineWidth = 2;
                context.strokeStyle = theme === 'dark' ? '#D5FF2F' : '#31333F';
                context.lineCap = 'round'; // Сглаживание углов
                context.lineJoin = 'round';
                context.beginPath();
                context.moveTo(tails[0].x * dpi, tails[0].y * dpi);

                for (let i = 1; i < tails.length; i++) {
                    context.lineTo(tails[i].x * dpi, tails[i].y * dpi);
                }

                context.stroke();
            }

            requestAnimationFrame(drawLine);
        }
    }

    function init() {
        if (!tabletIs && !mobileIs) {
            context = canvas.current.getContext('2d');

            window.addEventListener('mousemove', addDotsline, { passive: true });
            drawLine();
        }
    }

    function destroy() {
        window.removeEventListener('mousemove', addDotsline);
        // cancelAnimationFrame(drawLine);
        context = null;
    }

    useEffect(() => {
        init();

        return () => {
            destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theme]);

    return (
        <div className={style['cursor-container']}>
            <canvas ref={canvas} width={window.innerWidth} height={window.innerHeight} />
        </div>
    );
};

export default DrawLine;
