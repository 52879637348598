import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import st from './Cases.module.scss';
import { ReactComponent as AboutIcon } from '../../../../assets/images/icons/about.svg';
import { ReactComponent as SolutionIcon } from '../../../../assets/images/icons/solution.svg';
import { ReactComponent as CasesIcon } from '../../../../assets/images/icons/cases.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/images/icons/message.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/images/icons/arrow.svg';
import { ReactComponent as ErmineIcon } from '../../../../assets/images/icons/ermine-logo-2.svg';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const slides = [
    {
        image: '1',
        // icon: 'Worldwide',
    },
    {
        image: '2',
        // icon: 'Clients',
    },
    // {
    //     image: '3',
    //     icon: 'Amazon',
    // },
];

const Cases = () => {
    const intl = useIntl();
    const { tabletIs, mobileIs } = useSelector(({ UIStore }) => UIStore);
    const sliderRef = useRef(null);
    const progressCircle = useRef(null);

    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current?.style.setProperty('--progress', 1 - progress);
    };

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                <div className="row">
                    <div className="col-3 col-md-3 col-lg-2 col-xl-3">
                        <h2 className={st.title}>
                            <span className={st.title_1}>{intl.formatMessage({ id: 'home.cases.title' })}</span>
                            <span className={st.title_2}>{intl.formatMessage({ id: 'home.cases.title2' })}</span>
                            <a href="/#" className={st.title_3} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.cases.title3' }) }}>
                            </a>
                        </h2>
                    </div>
                    <div className="d-none d-md-block col-md-1 col-lg-2 col-xl-1"></div>
                    <div className="col-3 col-md-2 col-lg-2 col-xl-2">
                        <Link to="/about" className={st.card}>
                            <div className={st.card__body}>
                                <AboutIcon />
                                {intl.formatMessage({ id: 'home.cases.about' })}
                            </div>
                        </Link>
                    </div>
                    <div className="col-3 col-md-2 col-lg-2 col-xl-2">
                        <Link to="/services" className={st.card}>
                            <div className={st.card__body}>
                                <SolutionIcon />
                                {intl.formatMessage({ id: 'home.cases.solution' })}
                            </div>
                        </Link>
                    </div>
                    <div className="col-3 col-md-2 col-lg-2 col-xl-2">
                        <Link to="//erminecms.com/" target="_blank" className={st.card}>
                            <div className={st.card__body}>
                                <ErmineIcon />
                                {intl.formatMessage({ id: 'home.cases.ermine' })}
                            </div>
                        </Link>
                    </div>
                    <div className="d-none d-lg-block col-lg-2 col-xl-5"></div>

                    <div className="d-none d-md-block col-md-2 col-lg-2 col-xl-2"></div>
                    <div className="col-3 col-md-2 col-lg-2 col-xl-2">
                        <Link to="/work" className={st.card}>
                            <div className={st.card__body}>
                                <CasesIcon />
                                {intl.formatMessage({ id: 'home.cases.cases' })}
                            </div>
                        </Link>
                    </div>
                    <div className="col-3 col-md-2 col-lg-2 col-xl-2">
                        <Link to="/get-in-touch" className={st.card}>
                            <div className={st.card__body}>
                                <MessageIcon />
                                {intl.formatMessage({ id: 'home.cases.message' })}
                            </div>
                        </Link>
                    </div>
                </div>

                <div className={st.slider}>
                    <div className="row">
                        {!tabletIs && (
                            <>
                                <div className="d-none d-xl-block col-xl-2">
                                    <div className={st.progressWrapper}>
                                        <div className={st.progress} ref={progressCircle}>
                                            <div className={st.progress__line} />
                                        </div>
                                    </div>
                                </div>

                                <div className={classNames('col-lg-1 col-xl-1', st.slider__col)}>
                                    <div className={st.slider__buttons}>
                                        <button
                                            className={classNames('btn', st.slider__button, st.slider__button_prev)}
                                            onClick={() => sliderRef.current.swiper.slidePrev()}
                                            type="button"
                                        >
                                            <ArrowIcon />
                                        </button>
                                        <button
                                            className={classNames('btn', st.slider__button, st.slider__button_next)}
                                            onClick={() => sliderRef.current.swiper.slideNext()}
                                            type="button"
                                        >
                                            <ArrowIcon />
                                        </button>
                                    </div>
                                </div>

                                <div className="d-none d-lg-block col-lg-1 col-xl-1"></div>
                                <div className={classNames('col-lg-2 col-xl-2', st.slider__col)}>
                                    <div className={st['slider__slide-name']}>
                                        <span>We're trusted </span>
                                        around the world
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="col col-lg-8 col-xl-9">
                            <Swiper
                                ref={sliderRef}
                                watchOverflow
                                observer={true}
                                observeParents={true}
                                spaceBetween={0}
                                slidesPerView={1}
                                loop={true}
                                loopedSlides={2}
                                speed={1000}
                                modules={[EffectFade, Autoplay]}
                                effect="fade"
                                fadeEffect={{
                                    crossFade: true,
                                }}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                onAutoplayTimeLeft={onAutoplayTimeLeft}
                                className={st.slider__wrapper}
                            >
                                {[...slides, ...slides].map((item, i) => {
                                    return (
                                        <SwiperSlide key={i} className={st['slider__slide']}>
                                            <div className={st['slider__slide__image']}>
                                                <img src={require(`./images/slide-${item.image}.png`)} alt={`${i}`} />
                                            </div>
                                            {item.icon ? (
                                                <div className={st['slider__slide__name']}>{item.icon}</div>
                                            ) : (
                                                <div className={st['slider__slide__name']}>{item.name}</div>
                                            )}
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                        <div className="col-4 col-md-4 col-lg-2 col-xl-2">
                            {tabletIs && (
                                <div className={classNames('col-lg-2 col-xl-2', st.slider__col)}>
                                    <div className={st['slider__slide-name']}>
                                        <span>We're trusted </span>
                                        around the world
                                    </div>
                                </div>
                            )}
                        </div>
                        {!mobileIs && (
                            <div className={classNames('col-md col-lg-2 col-xl-2', st.bottom)}>
                                <div className={st.bottom__text}>A litte bit of everything we are proud of</div>
                            </div>
                        )}
                        {tabletIs && (
                            <div className={classNames('col-auto col-lg-1 col-xl-1', st.slider__col)}>
                                <div className={st.slider__buttons}>
                                    <button
                                        className={classNames('btn', st.slider__button, st.slider__button_prev)}
                                        onClick={() => sliderRef.current.swiper.slidePrev()}
                                        type="button"
                                    >
                                        <ArrowIcon />
                                    </button>
                                    <button
                                        className={classNames('btn', st.slider__button, st.slider__button_next)}
                                        onClick={() => sliderRef.current.swiper.slideNext()}
                                        type="button"
                                    >
                                        <ArrowIcon />
                                    </button>
                                </div>
                            </div>
                        )}
                        {mobileIs && (
                            <div className={classNames('col-6 col-md col-lg-2 col-xl-2', st.bottom)}>
                                <div className={st.bottom__text}>
                                    Multidisciplinary agency, focusing on helping brands, products, startups and companies
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Cases;
