/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import st from './Header.module.scss';
import { NavLink, Link } from 'react-router-dom';
import { NonAuthRoutes } from '../../routes/routes';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import ThemeSwitcher from '../ThemeSwitcher';

const Header = () => {
    const intl = useIntl();
    const [menuPopup, setMenuPopup] = React.useState(false);
    const { mobileIs } = useSelector(({ UIStore }) => UIStore);

    const handleToggleMenu = () => {
        setMenuPopup(!menuPopup);

        if (!menuPopup) {
            // document.documentElement.style.setProperty("top", `-${window.scrollY}px`);
            document.documentElement.classList.add('is-lock-scroll');
        } else {
            document.documentElement.classList.remove('is-lock-scroll');
            // document.documentElement.style.setProperty("top", "");
        }
    };

    return (
        <>
            <header className={st.wrapper}>
                <div className={st.container} data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="300">
                    <ThemeSwitcher />

                    <div className="row">
                        <div className="col col-md-3 col-lg-4 col-xl-6">
                            <Link to={NonAuthRoutes.home} className={st.logo}>
                                {intl.formatMessage({ id: 'app.header.basis' })}
                            </Link>
                        </div>

                        {mobileIs ? (
                            <div className="col-auto">
                                <button className={classNames('btn', st.burger, menuPopup ? st.burger_active : '')} type="button" onClick={handleToggleMenu}>
                                    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM12 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM12 10.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM3 10.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                                    </svg>
                                    <span>{intl.formatMessage({ id: 'app.header.menu.menu' })}</span>
                                </button>
                            </div>
                        ) : (
                            <>
                                <div className={classNames("col-auto", st.link__wrapper)}>
                                    <NavLink to={NonAuthRoutes.about} className={st.link}>
                                        {({ isActive }) => (
                                            <>
                                                {intl.formatMessage({ id: 'app.header.menu.about' })} {isActive ? '-' : '+'}
                                            </>
                                        )}
                                    </NavLink>
                                </div>

                                <div className={classNames("col-auto", st.link__wrapper)}>
                                    <NavLink to={NonAuthRoutes.work} className={st.link}>
                                        {({ isActive }) => (
                                            <>
                                                {intl.formatMessage({ id: 'app.header.menu.work' })} {isActive ? '-' : '+'}
                                            </>
                                        )}
                                    </NavLink>
                                </div>

                                <div className={classNames("col-auto", st.link__wrapper)}>
                                    <NavLink to={NonAuthRoutes.services} className={st.link}>
                                        {({ isActive }) => (
                                            <>
                                                {intl.formatMessage({ id: 'app.header.menu.services' })} {isActive ? '-' : '+'}
                                            </>
                                        )}
                                    </NavLink>
                                </div>

                                {/*<div className={classNames("col-auto", st.link__wrapper)}>
                                    <NavLink to={NonAuthRoutes.blog} className={st.link}>
                                        {({ isActive }) => (
                                            <>
                                                {intl.formatMessage({ id: 'app.header.menu.blog' })} {isActive ? '-' : '+'}
                                            </>
                                        )}
                                    </NavLink>
                                </div>*/}

                                <div className="col">
                                    <a href="https://erminecms.com" rel="noreferrer" target="_blank" className={st.link}>
                                        {intl.formatMessage({ id: 'app.header.menu.ermine' })}
                                    </a>
                                </div>

                                <div className="col-auto">
                                    <NavLink to={NonAuthRoutes.getInTouch} className={st.link}>
                                        {intl.formatMessage({ id: 'app.header.get' })}
                                    </NavLink>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </header>
            {mobileIs && (
                <div className={classNames(st.popup, menuPopup ? st.popup_active : '')}>
                    <div className={st.popup__bg}></div>
                    <div className={st.popup__content}>
                        <nav className={st.popup__nav}>
                            <NavLink to={NonAuthRoutes.home} className={st.popup__button} onClick={handleToggleMenu}>
                                {({ isActive }) => (
                                    <>
                                        {intl.formatMessage({ id: 'app.header.menu.home' })} {isActive ? '-' : '+'}
                                    </>
                                )}
                            </NavLink>

                            <NavLink to={NonAuthRoutes.about} className={st.popup__button} onClick={handleToggleMenu}>
                                {({ isActive }) => (
                                    <>
                                        {intl.formatMessage({ id: 'app.header.menu.about' })} {isActive ? '-' : '+'}
                                    </>
                                )}
                            </NavLink>

                            <NavLink to={NonAuthRoutes.work} className={st.popup__button} onClick={handleToggleMenu}>
                                {({ isActive }) => (
                                    <>
                                        {intl.formatMessage({ id: 'app.header.menu.work' })} {isActive ? '-' : '+'}
                                    </>
                                )}
                            </NavLink>

                            <NavLink to={NonAuthRoutes.services} className={st.popup__button} onClick={handleToggleMenu}>
                                {({ isActive }) => (
                                    <>
                                        {intl.formatMessage({ id: 'app.header.menu.services' })} {isActive ? '-' : '+'}
                                    </>
                                )}
                            </NavLink>

                            {/*<NavLink to={NonAuthRoutes.blog} className={st.popup__button} onClick={handleToggleMenu}>
                                {({ isActive }) => (
                                    <>
                                        {intl.formatMessage({ id: 'app.header.menu.blog' })} {isActive ? '-' : '+'}
                                    </>
                                )}
                            </NavLink>*/}

                            <NavLink to={NonAuthRoutes.getInTouch} className={st.popup__button} onClick={handleToggleMenu}>
                                {({ isActive }) => (
                                    <>
                                        {intl.formatMessage({ id: 'app.header.get' })} {isActive ? '-' : '+'}
                                    </>
                                )}
                            </NavLink>

                            <a href="https://erminecms.com" target="_blank" rel="noreferrer" className={st.popup__button} onClick={handleToggleMenu}>
                                {intl.formatMessage({ id: 'app.header.menu.ermine' })}
                            </a>
                        </nav>
                    </div>
                </div>
            )}
        </>
    );
};

export default Header;
