// @ts-nocheck
import React from 'react';
import st from './home.module.scss';
import { Helmet } from 'react-helmet-async';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Cases from './components/Cases/Cases';
import About from './components/About/About';
import Work from './components/Work/Work';

export const Home = () => {
    return (
        <>
            <Helmet>
                <title>BrandMyBrand - Home page</title>
            </Helmet>

            <Header />

            <main className={st.main}>
                <Hero />
                <Cases />
                <About />
                <Work />
            </main>

            <Footer />
        </>
    );
};
