// @ts-nocheck
import React, { useState } from 'react';
import st from './Footer.module.scss';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { NonAuthRoutes } from 'routes/routes';
import { useLocalUrl } from 'helpers/helpers';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Marquee from 'react-fast-marquee';

const Footer = ({ bgColor = undefined }) => {
    const intl = useIntl();
    const wrapperRef = React.useRef(null);
    const topRef = React.useRef(null);
    const year = new Date().getFullYear();
    const { mobileIs } = useSelector(({ UIStore }) => UIStore);
    const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0 });

    const mouseMoveHandler = (event) => {
        if (mobileIs) return;

        setMouseCoordinates({
            x: event.clientX,
            y: event.clientY - topRef.current.getBoundingClientRect().y,
        });
    };

    return (
        <>
            <footer className={st.wrapper} id="footer" ref={wrapperRef} style={{ backgroundColor: bgColor }}>
                <div className={st.container}>
                    <div
                        ref={topRef}
                        className={classNames('row', st.top)}
                        onMouseMove={mouseMoveHandler}
                        style={{ '--x': `${mouseCoordinates.x}px`, '--y': `${mouseCoordinates.y}px` }}
                    >
                        {!mobileIs && (
                            <Link to={NonAuthRoutes.getInTouch} className={st.work}>
                                {intl.formatMessage({ id: 'home.create.title' })}
                            </Link>
                        )}
                        <h2 className={classNames(st.title, 'marqueeWrapper')} data-aos="fade-up">
                            <Link to={NonAuthRoutes.getInTouch}>
                                <Marquee autoFill speed={80}>
                                    {intl.formatMessage({ id: 'home.create.title' })}
                                </Marquee>
                            </Link>
                        </h2>
                    </div>
                    <div className={classNames('row', st.body)}>
                        <div className="col-2 col-md-1 col-lg-3 col-xl-2">
                            <p className={st.basis} data-aos="fade-up">{intl.formatMessage({ id: 'home.create.basis' })}</p>
                        </div>
                        <div className="col-4 col-lg-3 col-xl-3">
                            <h2 className={st.text} data-aos="fade-up">
                                <span className={st.text_1}>{intl.formatMessage({ id: 'app.footer.title1' })}</span>
                                <span className={st.text_2}>{intl.formatMessage({ id: 'app.footer.title2' })}</span>
                                <span className={st.text_3}>{intl.formatMessage({ id: 'app.footer.title3' })}</span>
                                {/*<a href="/#" className={st.text_3}>
                                    {intl.formatMessage({ id: 'app.footer.title3' })}
                                </a>*/}
                            </h2>
                        </div>
                    </div>
                    <div className={classNames('row', st.bottom)}>
                        <div className="d-none d-lg-block col-lg-4 col-xl-6"> </div>
                        <div className="col-6 col-md-6 col-lg-3 col-xl-4">
                            <div className={classNames(st.social, st.social_links)}>
                                <a href="http://behance.net/brandmybrand" target="_blank" rel="noreferrer" className={st.social__link}>
                                    {intl.formatMessage({ id: 'home.create.social.behance' })}
                                </a>
                                <a href="https://instagram.com/brandmybrandio/" target="_blank" rel="noreferrer" className={st.social__link}>
                                    {intl.formatMessage({ id: 'home.create.social.instagram' })}
                                </a>
                                <a href="https://linkedin.com/company/brandmybrand-io/" target="_blank" rel="noreferrer" className={st.social__link}>
                                    {intl.formatMessage({ id: 'home.create.social.linkedin' })}
                                </a>
                                <p className={st.social__text}>{intl.formatMessage({ id: 'home.create.social.text' })}</p>
                            </div>
                            <div className={classNames(st.social, st.social_mail)}>
                                <a href="mailto:hello@brandmybrand.io" target="_blank" rel="noreferrer" className={st.social__link}>
                                    {intl.formatMessage({ id: 'home.create.social.mail' })}
                                </a>
                                <p className={st.social__text}>{intl.formatMessage({ id: 'home.create.social.connect' })}</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 col-lg-2 col-xl-4">
                            <div className={classNames(st.social, st.social_messages)}>
                                <a href="https://t.me/BrandMyBrandBot" target="_blank" rel="noreferrer" className={st.social__link}>
                                    {intl.formatMessage({ id: 'home.create.social.telegram' })}
                                </a>
                                <a href="https://wa.me/+436641333558" target="_blank" rel="noreferrer" className={st.social__link}>
                                    {intl.formatMessage({ id: 'home.create.social.whatsapp' })}
                                </a>
                                <p className={st.social__text}>{intl.formatMessage({ id: 'home.create.social.quick' })}</p>
                            </div>
                        </div>
                    </div>
                    <div className={classNames('row', st.footer)}>
                        <div className="col-auto col-md-1 col-lg-4 col-xl-6">
                            <div className={st.year}>&copy; {year}</div>
                        </div>
                        <div className="col">
                            <Link to={useLocalUrl(NonAuthRoutes.privacy)} className={st.logo}>
                                {intl.formatMessage({ id: 'app.footer.privacy' })}
                            </Link>
                        </div>

                        <div className="col-auto">
                            <div className={st.love}>
                                {intl.formatMessage({ id: 'app.footer.love' })}
                                <span className={st.love__dot}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
