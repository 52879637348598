import React from 'react';
import { useIntl } from 'react-intl';
import st from './Message.module.scss';
// import classNames from 'classnames';
import { ReactComponent as GlobeIcon } from '../../../../assets/images/icons/globe.svg';
import { useSelector } from 'react-redux';

const Message = () => {
    const intl = useIntl();
    const { tabletIs } = useSelector(({ UIStore }) => UIStore);

    // const handleTextarea = (e) => {
    //     const textarea = e.target;
    //     textarea.style.height = '';
    //     textarea.style.height = textarea.scrollHeight + 3 + 'px';
    // };

    return (
        <div className={st.wrapper}>
            {!tabletIs && (
                <>
                    <h2 className={st.title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'getintouch.message.title' }) }} />
                    <GlobeIcon className={st.globe} />
                </>
            )}

            <form className={st.form}>
                   <h2 className={st.title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'getintouch.message.title2' }) }} />
{/*                <textarea
                    className={st.field}
                    placeholder={intl.formatMessage({ id: 'getintouch.message.placeholder' })}
                    onInput={(e) => handleTextarea(e)}
                />
                <button className={classNames('btn', st.button)}>
                    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.00403 3.0605L1.54878 9.51575L0.488281 8.45525L6.94278 2H1.25403V0.5H9.50403V8.75H8.00403V3.0605Z" />
                    </svg>
                </button>*/}
            </form>
        </div>
    );
};

export default Message;
