// @ts-nocheck
import React from 'react';
import st from './services.module.scss';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Service from './components/Service/Service';
// import Work from 'pages/Home/components/Work/Work';
// import Testimonials from './components/Testimonials/Testimonials';
// import Breadcrumbs from 'components/Breadcrumbs';
// import { NonAuthRoutes } from 'routes/routes';

export const ServicesPage = () => {
    return (
        <>
            <Header />

            <main className={st.main}>
                <Hero />
                <Service />
                {/*<Work />*/}
                {/*<Testimonials />*/}
                {/*<Breadcrumbs
                    links={[
                        {
                            name: 'Home',
                            link: NonAuthRoutes.home,
                        },
                        {
                            name: 'Services',
                        },
                    ]}
                />*/}
            </main>

            <Footer bgColor="#31333F" />
        </>
    );
};
