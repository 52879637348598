import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";
import ruLocale from "date-fns/locale/ru";

const LocaleMap = {
    en: enLocale,
    de: deLocale,
    ru: ruLocale,
};

export const Languages = {
    de: { title: "German", shortName: "De", icon: "de.svg" },
    en: { title: "English", shortName: "En", icon: "en.svg" },
    ru: { title: "Russian", shortName: "Ru", icon: "ru.svg" },
};

export { LocaleMap };
