import { combineReducers } from 'redux';

import { UIStore } from './UIStore';
import { history } from './history';


export default combineReducers({
    UIStore,
    history,
});
