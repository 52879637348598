// @ts-nocheck
import React from 'react';
import { useIntl } from 'react-intl';
import st from './Service.module.scss';
import { ReactComponent as ArrowIcon } from '../../../../assets/images/icons/arrow.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/icons/arrow-right.svg';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { NonAuthRoutes } from 'routes/routes';

const Service = () => {
    const intl = useIntl();
    const { id } = useParams();

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                <div className={st.picture} />

                <div className={classNames('row', st.top)}>
                    <div className="col-2 col-md-2 col-lg-3 col-xl-4" />
                    <div className="col">
                        <h2 className={classNames(st.title)}>
                            {intl.formatMessage({ id: `services.${id}.service.serve.title` })}
                        </h2>
                    </div>
                </div>

                <div className={classNames('row', st.middle)}>
                    <div className="col-2 col-md-2 col-lg-3 col-xl-4">{intl.formatMessage({ id: `services.${id}.service.serve.accordion.title` })}</div>
                    <div className="col">{intl.formatMessage({ id: `services.${id}.service.serve.accordion.text` })}</div>
                </div>

                <div className={st.accordion}>
                    <div className={st.accordion__item}>
                        <div className="row">
                            <div className="col-2 col-md-2 col-lg-3 col-xl-4">
                                <div className={st.accordion__count}>{intl.formatMessage({ id: `services.${id}.service.serve.accordion.item1.count` })}</div>
                            </div>
                            <div className="col-4 col-md-6 col-lg-9 col-xl-11">
                                <div className={classNames('btn', st.accordion__button)}>
                                    <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `services.${id}.service.serve.accordion.item1.title` }) }} />
                                    <div className={st.accordion__icon}>
                                        <ArrowIcon />
                                    </div>
                                </div>
                                <div className={st.accordion__dropdown}>
                                    <p>{intl.formatMessage({ id: `services.${id}.service.serve.accordion.item1.text` })}</p>

                                    <Link to={`${NonAuthRoutes.services}/design/website`} className={st.link_mini}>
                                        {intl.formatMessage({ id: `services.${id}.service.link` })}
                                        <ArrowRightIcon className={st.link_mini__icon} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={st.accordion__item}>
                        <div className="row">
                            <div className="col-2 col-md-2 col-lg-3 col-xl-4">
                                <div className={st.accordion__count}>{intl.formatMessage({ id: `services.${id}.service.serve.accordion.item2.count` })}</div>
                            </div>
                            <div className="col-4 col-md-6 col-lg-9 col-xl-11">
                                <div className={classNames('btn', st.accordion__button)}>
                                    <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `services.${id}.service.serve.accordion.item2.title` }) }} />
                                    <div className={st.accordion__icon}>
                                        <ArrowIcon />
                                    </div>
                                </div>
                                <div className={st.accordion__dropdown}>
                                    <p>{intl.formatMessage({ id: `services.${id}.service.serve.accordion.item2.text` })}</p>
                                    <Link to={`${NonAuthRoutes.services}/design/website`} className={st.link_mini}>
                                        {intl.formatMessage({ id: `services.${id}.service.link` })}
                                        <ArrowRightIcon className={st.link_mini__icon} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={st.accordion__item}>
                        <div className="row">
                            <div className="col-2 col-md-2 col-lg-3 col-xl-4">
                                <div className={st.accordion__count}>{intl.formatMessage({ id: `services.${id}.service.serve.accordion.item3.count` })}</div>
                            </div>
                            <div className="col-4 col-md-6 col-lg-9 col-xl-11">
                                <div className={classNames('btn', st.accordion__button)}>
                                    <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `services.${id}.service.serve.accordion.item3.title` }) }} />
                                    <div className={st.accordion__icon}>
                                        <ArrowIcon />
                                    </div>
                                </div>
                                <div className={st.accordion__dropdown}>
                                    <p>
                                        {intl.formatMessage({ id: `services.${id}.service.serve.accordion.item3.text` })}
                                    </p>

                                    <Link to={`${NonAuthRoutes.services}/design/website`} className={st.link_mini}>
                                        {intl.formatMessage({ id: `services.${id}.service.link` })}
                                        <ArrowRightIcon className={st.link_mini__icon} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Service;
