// @ts-nocheck
import React from 'react';
import st from './work.module.scss';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import { useSelector } from 'react-redux';
import { ReactComponent as GlobeIcon } from '../../assets/images/icons/globe.svg';
// import { ReactComponent as LinkIcon } from '../../assets/images/icons/link.svg';
// import { ReactComponent as ArrowRightIcon } from '../../assets/images/icons/arrow-right.svg';
import classNames from 'classnames';
import { NonAuthRoutes } from 'routes/routes';
import { Link } from 'react-router-dom';

export const WorkPage = () => {
    const intl = useIntl();
    const { id } = useParams();
    const { mobileIs } = useSelector(({ UIStore }) => UIStore);

    let caseNumber = '';

    function changeIdToNext (id) {
        switch (id) {
            case 'vipsfirst':
                caseNumber = '02';
                return 'k2';
            case 'k2':
                caseNumber = '03';
                return 'fadorealestate';
            case 'fadorealestate':
                caseNumber = '04';
                return 'arthromed';
            case 'arthromed':
                caseNumber = '05';
                return 'armandiart';
            case 'armandiart':
                caseNumber = '06';
                return 'qumar';
            case 'qumar':
                caseNumber = '07';
                return 'adbos';
            case 'adbos':
                caseNumber = '08';
                return 'beautytown';
            case 'beautytown':
                caseNumber = '09';
                return 'fsexpert';
            case 'fsexpert':
                caseNumber = '10';
                return 'deinarzt';
            case 'deinarzt':
                caseNumber = '11';
                return 'arabiandigitalsolutions';
            case 'arabiandigitalsolutions':
                caseNumber = '12';
                return 'alpine';
            case 'alpine':
                caseNumber = '13';
                return 'naschmarktdeli';
            case 'naschmarktdeli':
                caseNumber = '14';
                return 'logofolio-1';
            case 'logofolio-1':
                caseNumber = '01';
                return 'vipsfirst';

            default:
              return 'work';
          }
    };
    let nextCaseId = changeIdToNext(id);

    return (
        <>
            <Header />

            <main className={st.main}>
                <section className={st.hero}>
                    <picture className={st.hero__image}><img src={require(`./images/${id}/hero.jpg`)} alt={intl.formatMessage({ id: `works.${id}.top.imgalt` })} /></picture>
                    <div className={st.container}>
                        <div className="row">
                            {!mobileIs && <div className="col-2 col-md-3 col-lg-4 col-xl-6"></div>}
                            <div className="col-6 col-md-5 col-lg-8 col-xl-9">
                                <h1 className={st.title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.${id}.top.title`}) }} />
                                <h1 className={st.subtitle} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.${id}.top.subtitle`}) }} />
                            </div>
                            <div className={st.scroll} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.work.scroll` }) }} />
                        </div>
                    </div>
                </section>

                <section className={st.info}>
                    <div className={st.container}>
                        <div className={classNames('row', st.info__top)}>
                            <div className="col-2 col-md-2 col-lg-1 col-xl-1">
                                <h2 className={st.info__title}>{intl.formatMessage({ id: `works.${id}.about.header1` })}</h2>
                            </div>
                            <div className="d-none d-lg-block col-lg-3 col-xl-5"></div>
                            <div className="col-3 col-md-2 col-lg-3 col-xl-2">
                                <p className={st.info__title}>{intl.formatMessage({ id: `works.${id}.about.header2` })}</p>
                            </div>
                            <div className={st.info__dot}></div>
                        </div>
                        <div className={classNames('row', st.info__body)}>
                            <div className="col-1 col-md-2 col-lg-1 col-xl-1">
                                <GlobeIcon className={st.info__globe} />
                            </div>
                            <div className="col-1 col-md-2 col-lg-3 col-xl-7"></div>
                            <div className="col-4 col-md-4 col-lg col-xl-7">
                                <div className={st.info__name}>
                                    <span className={st.info__name__text__dec}>{intl.formatMessage({ id: `works.${id}.about.text1` })}</span>
                                    <span className={st.info__name__text__dec}>{intl.formatMessage({ id: `works.${id}.about.text2` })}</span>
                                </div>
                            </div>
                        </div>
                        <div className={classNames('row', st.info__bottom)}>
                            {!mobileIs && (
                                <div
                                    className={classNames('col-1 col-md-3 col-lg-4 col-xl-6', st.scroll)}
                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.${id}.credits.explore` }) }}
                                />
                            )}
                            <div className="col-3 col-md-2 col-lg-3 col-xl-6">
                                <div className={st.credits__social}>
                                    <p
                                        className={st.credits__social__text}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.${id}.credits.services` }) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={st.pictures}>
                    <div className={st.container}>
                        <div className="row">
                            <div className="col-6 col-md-4">
                                <div className={classNames(st.pictures__image, st.pictures__image_small)}>{<img src={require(`./images/${id}/1.jpg`)} alt="" />}</div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className={classNames(st.pictures__image, st.pictures__image_small)}>{<img src={require(`./images/${id}/2.jpg`)} alt="" />}</div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className={classNames(st.pictures__image, st.pictures__image_small)}>{<img src={require(`./images/${id}/3.jpg`)} alt="" />}</div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className={classNames(st.pictures__image, st.pictures__image_big, st.pictures__image_large)}>
                                    {<img src={require(`./images/${id}/4.jpg`)} alt="" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={st.overview}>
                    <div className={st.container}>
                        <div className={classNames('row', st.overview__top)}>
                            <div className="col-2 col-md-4 col-lg-4 col-xl-6">
                                <div
                                    className={st.overview__name}
                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.${id}.overview1.name` }) }}
                                />
                            </div>
                            {/*<div className="d-none d-lg-block col-lg-3 col-xl-4">
                                <p className={st.overview__play}>{intl.formatMessage({ id: `works.${id}.overview1.play` })}</p>
                            </div>*/}
                            <div className="col-4 col-md-2 col-lg-3 col-xl-3">
                                <p className={st.overview__title}>{intl.formatMessage({ id: `works.${id}.overview1.title` })}</p>
                            </div>
                        </div>
                        <div className={classNames('row', st.info__body)}>
                            <div className="col-1 col-md-2 col-lg-1 col-xl-1">
                                <GlobeIcon className={st.info__globe} />
                            </div>
                            <div className="col-1 col-md-2 col-lg-3 col-xl-7"></div>
                            <div className="col-4 col-md-4 col-lg col-xl-7">
                                <div className={st.overview__text}>
                                    <span className={st.overview__text__dec}>{intl.formatMessage({ id: `works.${id}.overview1.text` })}</span>
                                    <span className={st.overview__text__dec}>{intl.formatMessage({ id: `works.${id}.overview1.text2` })}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={st.pictures}>
                    <div className={st.container}>
                        <div className="row">
                            <div className="col-6 col-md-4">
                                <div className={classNames(st.pictures__image, st.pictures__image_big, st.pictures__image_large)}>
                                    {<img src={require(`./images/${id}/5.jpg`)} alt="" />}
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className={classNames(st.pictures__image, st.pictures__image_small)}>{<img src={require(`./images/${id}/6.jpg`)} alt="" />}</div>
                            </div>
                            {/*<div className="col-6 col-md-4">
                                <div className={classNames(st.pictures__text)}>{intl.formatMessage({ id: 'works.example.picture.text' })}</div>
                            </div>*/}
                            <div className="col-6">
                                <div className={classNames(st.pictures__image, st.pictures__image_big, st.pictures__image_large)}>
                                    {<img src={require(`./images/${id}/7.jpg`)} alt="" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={st.pictures}>
                    <div className={st.container}>
                        <div className="row">
                            <div className="col-6">
                                <div className={classNames(st.pictures__image, st.pictures__image_big, st.pictures__image_large)}>
                                    {<img src={require(`./images/${id}/8.jpg`)} alt="" />}
                                </div>
                            </div>
                            {/*<div className="col-6 col-md-4">
                                <div className={classNames(st.pictures__text)}>{intl.formatMessage({ id: 'works.example.picture.text' })}</div>
                            </div>*/}
                           {/* <div className="d-none d-md-block col-md-4">
                                <div className={classNames(st.pictures__image_small)}></div>
                            </div>*/}
                            <div className="col-6 col-md-4">
                                <div className={classNames(st.pictures__image, st.pictures__image_small)}>{<img src={require(`./images/${id}/9.jpg`)} alt="" />}</div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className={classNames(st.pictures__image, st.pictures__image_small)}>{<img src={require(`./images/${id}/10.jpg`)} alt="" />}</div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className={classNames(st.pictures__image, st.pictures__image_small)}>{<img src={require(`./images/${id}/11.jpg`)} alt="" />}</div>
                            </div>
                            <div className="d-none d-md-block col-md-4"></div>
                            <div className="col-6">
                                <div className={classNames(st.pictures__image, st.pictures__image_small)}>{<img src={require(`./images/${id}/12.jpg`)} alt="" />}</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={st.overview}>
                    <div className={st.container}>
                        <div className={classNames('row', st.overview__top)}>
                            <div className="col-2 col-md-4 col-lg-4 col-xl-6">
                                <div
                                    className={st.overview__name}
                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.${id}.overview2.name` }) }}
                                />
                            </div>
                            {/*<div className="d-none d-lg-block col-lg-3 col-xl-4">
                                <p className={st.overview__play}>{intl.formatMessage({ id: `works.${id}.overview2.play` })}</p>
                            </div>*/}
                            <div className="col-3 col-md-2 col-lg-3 col-xl-2">
                                <p className={st.overview__title}>{intl.formatMessage({ id: `works.${id}.overview2.title` })}</p>
                            </div>
                        </div>
                        <div className={classNames('row', st.info__body)}>
                            <div className="col-1 col-md-2 col-lg-1 col-xl-1">
                                <GlobeIcon className={st.info__globe} />
                            </div>
                            <div className="col-1 col-md-2 col-lg-3 col-xl-7"></div>
                            <div className="col-4 col-md-4 col-lg col-xl-7">
                                <div className={st.overview__text}>
                                    <span className={st.overview__text__dec}>{intl.formatMessage({ id: `works.${id}.overview2.text` })}</span>
                                    <span className={st.overview__text__dec}>{intl.formatMessage({ id: `works.${id}.overview2.text2` })}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={st.pictures}>
                    <div className={st.container}>
                        <div className="row">
                            <div className="d-none d-md-block col-md-4"></div>
                            <div className="col-6">
                                <div className={classNames(st.pictures__image, st.pictures__image_big, st.pictures__image_large)}>
                                    {<img src={require(`./images/${id}/13.jpg`)} alt="" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={st.credits}>
                    <div className={st.container}>
                        <div className="row">
                            {!mobileIs && (<div className="col-1 col-md-3 col-lg-4 col-xl-6"></div>)}
                            <div className="col-3 col-md-2 col-lg-3 col-xl-2">
                                <div className={st.credits__social}>
                                    <p
                                        className={st.credits__social__text}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.${id}.credits.client` }) }}
                                    />
                                </div>
                                <div className={st.credits__social}>
                                    <p
                                        className={st.credits__social__text}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.${id}.credits.location` }) }}
                                    />
                                </div>
                                <div className={st.credits__social}>
                                    <p
                                        className={st.credits__social__text}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.${id}.credits.services` }) }}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <p className={st.credits__title}>{intl.formatMessage({ id: `works.work.credits` })}</p>
                            </div>
                        </div>
                    </div>
                </section>
                
                {/*NEXT CASE*/}
                <section className={st.info_other}>
                    <div className={classNames(st.container, st.container_other)}>
                        <div className={classNames('row', st.info__top)}>
                            <div className="col-2 col-md-4 col-lg-3 col-xl-3">
                                <h2 className={st.info__title}>{intl.formatMessage({ id: `works.work.case` })}</h2>
                            </div>
                            <div className="col-1 col-md-1 col-lg-1 col-xl-3"></div>
                            <div className="col-1 col-md-1 col-lg col-xl-1">
                                <p className={st.info__title}>[{caseNumber}]</p>
                            </div>
                            <div className={st.info__dot}></div>
                        </div>
                        <Link to={`${NonAuthRoutes.work}/${nextCaseId}`}> 
                        <div className={classNames('row', st.info__body)}>
                            <div className="col-1 col-md-2 col-lg-1 col-xl-1">
                                <div className={st.info__name__bottom}>
                                {intl.formatMessage({ id: `works.work.next` })}
                                </div>
                            </div>
                            <div className="col-2 col-md-3 col-lg-3 col-xl-5"></div>
                            <div className="col-3 col-md-3 col-lg col-xl-7">
                                <div className={st.info__subname}>
                                    <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.${nextCaseId}.top.title` }) }} />
                                    <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `works.${nextCaseId}.top.subtitle` }) }} />
                                </div>
                            </div>
                        </div>
                        <div className={classNames('row', st.info__bottom)}>
                            <div className="col-6">
                                <div className={classNames(st.pictures__image, st.pictures__image_big, st.pictures__image_large, st.pictures__image_next)}>
                                    {<img src={require(`./images/${nextCaseId}/hero.jpg`)} alt="" />}
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </section>
            </main>

            <Footer bgColor="#31333F" />
        </>
    );
};
