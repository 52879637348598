import React, { useEffect, useRef, useState } from 'react';

// styles
import styles from './Preloader.module.scss';
import classNames from 'classnames';
import AOS from 'aos';
import 'aos/dist/aos.css';
const rafSpeed = 0.03;

const Preloader = () => {
    const [hidden, setHidden] = useState(false);
    const [position, setPosition] = useState(0);
    const heights = [window.innerHeight - 12 * 4, 72];
    const range = heights[1] - heights[0];
    const requestRef = useRef(null);
    const previousTimeRef = useRef(null);

    const animate = (time) => {
        if (previousTimeRef.current !== undefined) {
            const deltaTime = time - previousTimeRef.current;

            // Pass on a function to the setter of the state
            // to make sure we always have the latest state
            if (process.env.NODE_ENV === 'development' || time * rafSpeed >= 100) {
                setPosition(100);
                setHidden(true);

                setTimeout(() => {
                    AOS.init({
                        delay: 200,
                        once: true,
                        duration: 500,
                        anchorPlacement: 'center-bottom',
                    });
                }, 400);

                return cancelAnimationFrame(requestRef.current);
            }

            setPosition((prevCount) => (prevCount + deltaTime * rafSpeed));
        }

        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    };

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);

        return () => cancelAnimationFrame(requestRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Make sure the effect runs only once

    return (
        <div
            className={classNames(
                styles.wrapper,
                hidden ? styles.is_hidden : styles.is_visible
            )}
            style={{
                // @ts-ignore
                '--position': (1 - position) * range  / 100,
            }}
        >
            <div className={styles.container}>
                <div className={styles.logo}>
                    BrandMyBrand <span className={(position).toFixed(0) === '100' ? styles.logo_active : ''}>{(position).toFixed(0)}</span>
                    <sup>®</sup>
                </div>
            </div>
        </div>
    );
};

export default Preloader;
