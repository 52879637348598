import React from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useSelector } from 'react-redux';

export const formatTime = (time) => {
    time = Math.max(time, 0);

    const sec = Math.floor((time / 1000) % 60)
        .toString()
        .padStart(2, '0');
    const min = Math.floor((time / 1000 / 60) % 60)
        .toString()
        .padStart(2, '0');
    const hrs = Math.floor((time / 1000 / 60 / 60) % 24)
        .toString()
        .padStart(2, '0');

    // @ts-ignore
    return `${ hrs } : ${ min } : ${ sec }`;
};

export const stringAvatar = (name) => {
    return {
        children: `${ name.split(' ')[0][0] }${ name.split(' ')[1][0] }`,
    };
};

export const objectToArray = (value) => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        return Object.values(value);
    }
    return value;
};

export const isObject = (value) => {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

export const neatNumber = (value, isPercent = false) => {
    let formatValue = value ? value.toFixed(2) : value === 0 ? 0 : '';
    if (value && isPercent) {
        formatValue += '%';
    }
    return formatValue;
};

export const useScrollbarWidth = () => {
    const didCompute = React.useRef(false);
    const widthRef = React.useRef(0);

    if (didCompute.current) return widthRef.current;

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    didCompute.current = true;
    widthRef.current = scrollbarWidth;

    return scrollbarWidth;
};

export const useOnClickOutside = (ref, handler) => {
    React.useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref, handler]);
};

/**
* Узнать есть доступен ли ховер
* @returns {boolean}
*/
function hasHoverSupport() {
    let hoverSupport;

    // if (useScrollbarWidth()) {
    // On touch devices scrollbar width is usually 0
    // hoverSupport = true;
    // } else
    if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0)) {
        hoverSupport = false;
    } else if (window.matchMedia('(any-hover: hover)').matches || window.matchMedia('(hover: hover)').matches) {
        hoverSupport = true;
    } else if (window.matchMedia('(hover: none)').matches) {
        hoverSupport = false;
    } else {
        hoverSupport = typeof document.documentElement.ontouchstart === 'undefined';
    }

    return hoverSupport;
}

export function detectBrowserHover() {
    if (!hasHoverSupport()) {
        document.documentElement.classList.remove('has-hover');
        document.documentElement.classList.add('no-hover');
    } else {
        document.documentElement.classList.remove('no-hover');
        document.documentElement.classList.add('has-hover');
    }
}

/**
 * example
 *
 * useBodyScrollLock(popupRef, {
 *    locked: true,
 * });
 */
export const useBodyScrollLock = (scrollLockTarget, options) => {
    // React.useEffect(() => {
    if (!scrollLockTarget || !scrollLockTarget.current) {
        return () => undefined;
    }

    const { locked, config } = options;

    if (typeof locked === 'undefined' || locked) {
        disableBodyScroll(scrollLockTarget.current, {
            reserveScrollBarGap: true,
            ...config,
            allowTouchMove: el => {
                while (el && el !== document.body) {
                    if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                        return true;
                    }

                    if (el.parentElement) {
                        el = el.parentElement;
                    }
                }
            },
        });
        document.body.setAttribute('top', `-${ window.scrollY }px`);
    } else if (!locked) {
        enableBodyScroll(scrollLockTarget.current);
        document.body.setAttribute('top', "");
        window.scrollTo(0, 2000);
    }

    //     return () => {
    //         clearAllBodyScrollLocks();
    //     };
    // }, [scrollLockTarget, options]);
};

export const useAutosizeTextArea = (textAreaRef, value) => {
    React.useEffect(() => {
        if (textAreaRef) {
            // We need to reset the height momentarily to get the correct scrollHeight for the textarea
            textAreaRef.style.height = '0px';
            const scrollHeight = textAreaRef.scrollHeight;

            // We then set the height directly, outside of the render loop
            // Trying to set this with state or a ref will product an incorrect value.
            textAreaRef.style.height = scrollHeight + 1 + 'px';
        }
    }, [textAreaRef, value]);
};

export const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const useLocalUrl = (value) => {
    const { locale } = useSelector(({ UIStore }) => UIStore);

    return ((locale && locale !== process.env.REACT_APP_DEFAULT_LOCALE ? "/" + locale : '') + value);
}
