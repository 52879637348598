import st from './ThemeSwitcher.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as Switch from '@radix-ui/react-switch';
import { themeModes } from '../../store/reducers/UIStore';

const ThemeSwitcher = () => {
    const dispatch = useDispatch();
    const { theme } = useSelector(({ UIStore }) => UIStore);
    const toggleTheme = () => {
        dispatch({ type: 'toggleTheme', value: (theme === themeModes.basis ? themeModes.alternate : themeModes.basis) });
    }

    return (
        <div className={st.themeSwitcher}>
            <Switch.Root className={st.switchRoot} onCheckedChange={toggleTheme} checked={theme === themeModes.alternate}>
                <Switch.Thumb className={st.switchThumb} />
            </Switch.Root>
        </div>
    );
};

export default ThemeSwitcher;
