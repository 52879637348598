// @ts-nocheck
// import React, { forwardRef, useRef, useState } from 'react';
import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import st from './Service.module.scss';
import { ReactComponent as ArrowIcon } from '../../../../assets/images/icons/arrow.svg';
// import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/icons/arrow-right.svg';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';
// import { NonAuthRoutes } from 'routes/routes';
import * as Accordion from '@radix-ui/react-accordion';
// import style from './Expertise.module.scss';
// import { ReactComponent as GlobeIcon } from '../../../../assets/images/icons/globe.svg';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/scss';
// import styles from './Cases.module.scss';

const AccordionTrigger = forwardRef(({ children, className, ...props }, forwardedRef) => {
    return (
        <Accordion.Header asChild>
            <Accordion.Trigger className={classNames('btn', className)} {...props} ref={forwardedRef}>
                {children}
            </Accordion.Trigger>
        </Accordion.Header>
    );
});
AccordionTrigger.displayName = 'AccordionTrigger';

const AccordionContent = forwardRef(({ children, className, ...props }, forwardedRef) => {
    return (
        <Accordion.Content className={className} {...props} ref={forwardedRef}>
            <div className={st.accordion__dropdown}>
                {children}
            </div>
        </Accordion.Content>
    );
});
AccordionContent.displayName = 'AccordionContent';

// const textList = [
//     {
//         text: 'Corporate',
//         key: 'corporate',
//     },
//     {
//         text: 'Real Estate',
//         key: 'realestate',
//     },
//     {
//         text: 'Healthcare',
//         key: 'healthcare',
//     },
//     {
//         text: 'Beautycare',
//         key: 'beautycare',
//     },
//     {
//         text: 'Custom',
//         key: 'custom',
//     },
// ]

// const slider = [
//     {
//         id: 1,
//         title: 1,
//     },
//     {
//         id: 2,
//         title: 2,
//     },
//     {
//         id: 3,
//         title: 3,
//     },
//     {
//         id: 4,
//         title: 4,
//     },
//     {
//         id: 5,
//         title: 5,
//     },
//     {
//         id: 6,
//         title: 6,
//     },
//     {
//         id: 7,
//         title: 7,
//     },
//     {
//         id: 8,
//         title: 8,
//     },
//     {
//         id: 9,
//         title: 9
//     }
// ];


const Service = () => {
    const intl = useIntl();
    // const itemsRef = useRef([]);
    // const [active, setActive] = useState(null);

    // const handleClick = (event, key) => {
    //     setActive(key)
    // }; 

    // const sliderRef = React.useRef(null)

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                <div className={st.picture} />

                <div className={classNames('row', st.top)}>
                    <div className="col-0 col-md-2 col-lg-3 col-xl-4" />
                    <div className="col">
                        <h2 className={classNames(st.title)}
                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'services.service.designanddevelopment.title' }) }}
                        />
                    </div>
                </div>

                {/*<div className={classNames('row')}>
                    <div className="col-2 col-md-2 col-lg-3 col-xl-4" />
                    <div className="col">
                        <Link to={`${NonAuthRoutes.services}/design-and-development`} className={st.link}>
                            {intl.formatMessage({ id: 'services.service.link' })}
                            <ArrowRightIcon className={st.link__icon} />
                        </Link>
                    </div>
                </div>*/}

                <div className={classNames('row', st.middle)}>
                    <div className="col-2 col-md-2 col-lg-3 col-xl-4">{intl.formatMessage({ id: 'services.service.designanddevelopment.accordion.title' })}</div>
                    <div className="col">{intl.formatMessage({ id: 'services.service.designanddevelopment.accordion.text' })}</div>
                </div>

                <Accordion.Root type='single' collapsible defaultValue='item-design-1' className={st.accordion}>
                    {[1, 2, 3, 4, 5, 6, 7].map(item => (
                        <Accordion.Item key={`design-${item}`} className={st.accordion__item} value={`item-design-${item}`}>
                            <div className="row">
                                <div className="col-2 col-md-2 col-lg-3 col-xl-4">
                                    <div className={st.accordion__count}>{intl.formatMessage({ id: `services.service.designanddevelopment.accordion.item${item}.count` })}</div>
                                </div>
                                <div className="col-4 col-md-6 col-lg-9 col-xl-11">
                                    <AccordionTrigger className={classNames('btn', st.accordion__button)}>
                                        <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `services.service.designanddevelopment.accordion.item${item}.title` }) }} />
                                        <div className={st.accordion__icon}>
                                            <ArrowIcon />
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent className={st.accordion__content}>{intl.formatMessage({ id: `services.service.designanddevelopment.accordion.item${item}.text` })}</AccordionContent>
                                </div>
                            </div>
                        </Accordion.Item>
                    ))}
                </Accordion.Root>


                {/*Types of sites*/}
                {/*<section className={style.wrapper}>
                    <div className={style.container}>
                        <div className={classNames('row', style.top)}>
                            <div className="col-2 col-md-2 col-lg-3 col-xl-4">
                                <h2 className={style.title}>{intl.formatMessage({ id: 'services.expertise.sites' })}</h2>
                            </div>
                            <div className="col">
                                <p className={style.title}>{intl.formatMessage({ id: 'services.expertise.wecreate' })}</p>
                            </div>
                            <div className={style.dot}></div>
                        </div>
                        <div className={classNames('row', style.body)}>
                            <div className="col-2 col-md-2 col-lg-3 col-xl-4">
                                <GlobeIcon className={style.globe} />
                            </div>
                            <div className="col-3 col-md-3 col-lg-4 col-xl-3">
                                <p className={style.text} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'about.expertise.text' }) }} /> 
                                {textList.map((item, i) => (
                                    <button type="button" key={i} ref={el => itemsRef.current[i] = el} onClick={(event) => handleClick(event, item.key)} className={classNames('btn', style.text, active === item.key && style.text_active)}>{item.text}</button>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>*/}

                {/*<section className={styles.wrapper}>
                    <div className={styles.slider}>
                        <div className={styles.slider__buttons}>
                            <button
                                className={classNames('btn', styles.slider__button, styles.slider__button_prev)}
                                onClick={() => sliderRef.current.swiper.slidePrev()}
                                type="button"
                            >
                                <ArrowIcon />
                            </button>
                            <button
                                className={classNames('btn', styles.slider__button, styles.slider__button_next)}
                                onClick={() => sliderRef.current.swiper.slideNext()}
                                type="button"
                            >
                                <ArrowIcon />
                            </button>
                        </div>

                        <Swiper
                            ref={sliderRef}
                            watchOverflow
                            observer={true}
                            observeParents={true}
                            spaceBetween={12}
                            slidesPerView="auto"
                            // initialSlide={2}
                            // loop={true}
                            // loopedSlides={2}
                            speed={1000}
                            className={styles.slider__wrapper}
                        >
                            {slider.map((item, i) => {
                                return (
                                    <SwiperSlide key={i} className={styles['slider__slide']}>
                                        <svg width="65" height="18" viewBox="0 0 65 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.90644 16.3769C7.93207 16.548 6.94055 16.5993 5.91488 16.7359L2.78643 7.57309V17.1292C1.81205 17.2317 0.923138 17.3685 0 17.5052V0H2.5985L6.15417 9.9323V0H8.90644V16.3769ZM14.2914 6.4106C15.3513 6.4106 16.9753 6.3593 17.9497 6.3593V9.09443C16.7359 9.09443 15.3171 9.09443 14.2914 9.14573V13.2144C15.8984 13.1119 17.5053 12.975 19.1292 12.9237V15.5561L11.5561 16.1546V0H19.1292V2.73519H14.2914V6.4106ZM29.3007 2.73526H26.4629V15.3171C25.5398 15.3171 24.6167 15.3171 23.7279 15.3512V2.73526H20.8902V0H29.3008L29.3007 2.73526ZM33.7453 6.22261H37.4892V8.95774H33.7453V15.1633H31.0612V0H38.7029V2.73519H33.7453V6.22261ZM43.1476 12.7699C44.7032 12.804 46.2759 12.9239 47.7974 13.0092V15.7102C45.3529 15.5563 42.9083 15.4026 40.4124 15.3512V0H43.1476V12.7699ZM50.1052 15.8983C50.977 15.9496 51.9002 16.0009 52.7891 16.1034V0H50.1052V15.8983ZM64.7727 0L61.3024 8.32526L64.7727 17.5052C63.7469 17.3685 62.7213 17.1804 61.6955 17.0094L59.7297 11.9494L57.7298 16.5993C56.7381 16.4282 55.7808 16.3769 54.7894 16.2401L58.311 8.22259L55.1312 0H58.0714L59.8664 4.59855L61.7811 0H64.7727V0Z"
                                                fill="white"
                                            />
                                        </svg>
                                        <div className={styles['slider__slide-text']}>{item.title}</div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </section>*/}

                {/*<div className={classNames('row', st.top)}>
                    <div className="col-2 col-md-2 col-lg-3 col-xl-4" />
                    <div className="col">
                        <h2 className={classNames(st.title)}>
                            {intl.formatMessage({ id: 'services.service.development.title' })}
                        </h2>
                    </div>
                </div>

                <div className={classNames('row', st.middle)}>
                    <div className="col-2 col-md-2 col-lg-3 col-xl-4">{intl.formatMessage({ id: 'services.service.development.accordion.title' })}</div>
                    <div className="col">{intl.formatMessage({ id: 'services.service.development.accordion.text' })}</div>
                </div>

                <Accordion.Root type='single' collapsible defaultValue='item-development-1' className={st.accordion}>
                    {[1, 2, 3].map(item => (
                        <Accordion.Item key={`development-${item}`} className={st.accordion__item} value={`item-development-${item}`}>
                            <div className="row">
                                <div className="col-2 col-md-2 col-lg-3 col-xl-4">
                                    <div className={st.accordion__count}>{intl.formatMessage({ id: `services.service.development.accordion.item${item}.count` })}</div>
                                </div>
                                <div className="col-4 col-md-6 col-lg-9 col-xl-11">
                                    <AccordionTrigger className={classNames('btn', st.accordion__button)}>
                                        <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `services.service.development.accordion.item${item}.title` }) }} />
                                        <div className={st.accordion__icon}>
                                            <ArrowIcon />
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent className={st.accordion__content}>{intl.formatMessage({ id: `services.service.development.accordion.item${item}.text` })}</AccordionContent>
                                </div>
                            </div>
                        </Accordion.Item>
                    ))}
                </Accordion.Root>*/}

                {/*<div className={classNames('row')}>
                    <div className="col-2 col-md-2 col-lg-3 col-xl-4" />
                    <div className="col">
                        <Link to={`${NonAuthRoutes.services}/development`} className={st.link}>
                            {intl.formatMessage({ id: 'services.service.link' })}
                            <ArrowRightIcon className={st.link__icon} />
                        </Link>
                    </div>
                </div>*/}

                <div className={classNames('row', st.top)}>
                    <div className="col-0 col-md-2 col-lg-3 col-xl-4" />
                    <div className="col">
                        <h2 className={classNames(st.title)}>
                            {intl.formatMessage({ id: 'services.service.social.title' })}
                        </h2>
                    </div>
                </div>

                <div className={classNames('row', st.middle)}>
                    <div className="col-2 col-md-2 col-lg-3 col-xl-4">{intl.formatMessage({ id: 'services.service.social.accordion.title' })}</div>
                    <div className="col">{intl.formatMessage({ id: 'services.service.social.accordion.text' })}</div>
                </div>

                <Accordion.Root type='single' collapsible defaultValue='item-social-1' className={st.accordion}>
                    {[1, 2, 3, 4, 5].map(item => (
                        <Accordion.Item key={`social-${item}`} className={st.accordion__item} value={`item-social-${item}`}>
                            <div className="row">
                                <div className="col-2 col-md-2 col-lg-3 col-xl-4">
                                    <div className={st.accordion__count}>{intl.formatMessage({ id: `services.service.social.accordion.item${item}.count` })}</div>
                                </div>
                                <div className="col-4 col-md-6 col-lg-9 col-xl-11">
                                    <AccordionTrigger className={classNames('btn', st.accordion__button)}>
                                        <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `services.service.social.accordion.item${item}.title` }) }} />
                                        <div className={st.accordion__icon}>
                                            <ArrowIcon />
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent className={st.accordion__content}>{intl.formatMessage({ id: `services.service.social.accordion.item${item}.text` })}</AccordionContent>
                                </div>
                            </div>
                        </Accordion.Item>
                    ))}
                </Accordion.Root>

                <div className={classNames('row', st.top)}>
                    <div className="col-0 col-md-2 col-lg-3 col-xl-4" />
                    <div className="col">
                        <h2 className={classNames(st.title)}>
                            {intl.formatMessage({ id: 'services.service.branding.title' })}
                        </h2>
                    </div>
                </div>

                <div className={classNames('row', st.middle)}>
                    <div className="col-2 col-md-2 col-lg-3 col-xl-4">{intl.formatMessage({ id: 'services.service.branding.accordion.title' })}</div>
                    <div className="col">{intl.formatMessage({ id: 'services.service.branding.accordion.text' })}</div>
                </div>

                <Accordion.Root type='single' collapsible defaultValue='item-branding-1' className={st.accordion}>
                    {[1, 2].map(item => (
                        <Accordion.Item key={`branding-${item}`} className={st.accordion__item} value={`item-branding-${item}`}>
                            <div className="row">
                                <div className="col-2 col-md-2 col-lg-3 col-xl-4">
                                    <div className={st.accordion__count}>{intl.formatMessage({ id: `services.service.branding.accordion.item${item}.count` })}</div>
                                </div>
                                <div className="col-4 col-md-6 col-lg-9 col-xl-11">
                                    <AccordionTrigger className={classNames('btn', st.accordion__button)}>
                                        <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `services.service.branding.accordion.item${item}.title` }) }} />
                                        <div className={st.accordion__icon}>
                                            <ArrowIcon />
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent className={st.accordion__content}>{intl.formatMessage({ id: `services.service.branding.accordion.item${item}.text` })}</AccordionContent>
                                </div>
                            </div>
                        </Accordion.Item>
                    ))}
                </Accordion.Root>

                {/*<div className={classNames('row')}>
                    <div className="col-2 col-md-2 col-lg-3 col-xl-4" />
                    <div className="col">
                        <Link to={`${NonAuthRoutes.services}/seo`} className={st.link}>
                            {intl.formatMessage({ id: 'services.service.link' })}
                            <ArrowRightIcon className={st.link__icon} />
                        </Link>
                    </div>
                </div>*/}
            </div>
        </section>
    );
};

export default Service;
