// import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import st from './Hero.module.scss';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/icons/arrow-right.svg';
import { useSelector } from 'react-redux';

const Hero = () => {
    const intl = useIntl();
    const { tabletIs } = useSelector(({ UIStore }) => UIStore);

    return (
        <div className={st.wrapper}>
            {!tabletIs && (
                <h1 className={st.title}>
                    <span className={st.title_1}>{intl.formatMessage({ id: 'getintouch.title' })}</span>
                    <span className={st.title_2}>{intl.formatMessage({ id: 'getintouch.title2' })}</span>
                    <span className={st.title_2}>{intl.formatMessage({ id: 'getintouch.title3' })}</span>
                    {/*<a href="/#" className={st.title_3}>
                         {intl.formatMessage({ id: 'getintouch.title3' })}
                    </a>*/}
                </h1>
            )}

            <div className={st.top}>
                <div className={st.user}>
                    <div className={st.name} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'getintouch.user.name' }) }} />
                    <picture className={st.user__picture}>
                        <img
                            // @ts-ignore
                            src={require('./images/user.png')}
                            alt="Katharina BrandMyBrand SEO"
                            width={40} 
                            height={40}
                        />
                    </picture>
                </div>
                {!tabletIs && (
                    <>
                        <div className={st.date}>{new Date().toLocaleDateString()}</div>
                        <div className={st.time}>{new Date().toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})}</div>
                    </>
                )}
                <div className={st.you}>You</div>
            </div>

            <div className={st.scroller}>
                <div className={st.message__item}>
                    <div className={st.message__col}>
                        <div className={st.message__first}>
                            <div
                                className={st.message__text}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'getintouch.message0.text' }) }}
                            />
                        </div>
                    </div>
                    <div className={st.message__col}>
                        <div className={st.message__second}>
                            {/*<div
                                className={classNames(st.message__text, st.message__text_2)}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'getintouch.message1.text' }) }}
                            />*/}
                            <a href="https://t.me/brandmybrand" target="_blank" rel="noreferrer" className={st.message__link} >
                                {intl.formatMessage({ id: 'getintouch.message1.link1' })}
                                <div className={st.message__icon}>
                                    <ArrowRightIcon />
                                </div>
                            </a>
                            <a href="https://t.me/brandmybrand" target="_blank" rel="noreferrer" className={st.message__link}>
                                {intl.formatMessage({ id: 'getintouch.message1.link2' })}
                                <div className={st.message__icon}>
                                    <ArrowRightIcon />
                                </div>
                            </a>
                            <a href="https://t.me/maksimberest" target="_blank" rel="noreferrer" className={st.message__link}>
                                {intl.formatMessage({ id: 'getintouch.message1.link3' })}
                                <div className={st.message__icon}>
                                    <ArrowRightIcon />
                                </div>
                            </a>
                            <a href="mailto:hello@brandmybrand.io" target="_blank" rel="noreferrer" className={st.message__link}>
                                {intl.formatMessage({ id: 'getintouch.message1.link4' })}
                                <div className={st.message__icon}>
                                    <ArrowRightIcon />
                                </div>
                            </a>
                            <a href="tel:+436641333558" target="_blank" rel="noreferrer" className={st.message__link}>
                                {intl.formatMessage({ id: 'getintouch.message1.link5' })}
                                <div className={st.message__icon}>
                                    <ArrowRightIcon />
                                </div>
                            </a>
                            <a href="https://indeed.com/brandmybrand" target="_blank" rel="noreferrer" className={st.message__link}>
                                {intl.formatMessage({ id: 'getintouch.message1.join' })}
                                <div className={st.message__icon}>
                                    <ArrowRightIcon />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
{/*                <div className={st.message__item}>
                    <div className={st.message__col}>
                        <div className={st.message__first}>
                            <div
                                className={st.message__text}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'getintouch.message2.text' }) }}
                            />
                        </div>
                    </div>
                    <div className={st.message__col}>
                        <div className={st.message__second}>
                            <a href="/#" className={st.message__link} target="_blank">
                                {intl.formatMessage({ id: 'getintouch.message3.link1' })}
                                <div className={st.message__icon}>
                                    <ArrowRightIcon />
                                </div>
                            </a>
                            <a href="/#" className={st.message__link} target="_blank">
                                {intl.formatMessage({ id: 'getintouch.message3.link2' })}
                                <div className={st.message__icon}>
                                    <ArrowRightIcon />
                                </div>
                            </a>
                            <a href="/#" className={st.message__link} target="_blank">
                                {intl.formatMessage({ id: 'getintouch.message3.link3' })}
                                <div className={st.message__icon}>
                                    <ArrowRightIcon />
                                </div>
                            </a>
                            <a href="/#" className={st.message__link} target="_blank">
                                {intl.formatMessage({ id: 'getintouch.message3.link4' })}
                                <div className={st.message__icon}>
                                    <ArrowRightIcon />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
    );
};

export default Hero;
