import React from 'react';
import { useIntl } from 'react-intl';
import st from './Body.module.scss';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

const Body = () => {
    const intl = useIntl();
    const { id, subid } = useParams();

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                <div className={classNames('row', st.body)}>
                    <div className="col-6 col-md-8 col-lg-3 col-xl-5">
                        <div className={st.title}>{intl.formatMessage({ id: `services.${id}.${subid}.body.title` })}</div>
                    </div>
                    <div className="d-none d-lg-block col-lg-1 col-xl-1" />
                    <div className={classNames("col-6 col-md-8 col-lg col-xl-9", st.content)}>
                        <div className={st.text}>{intl.formatMessage({ id: `services.${id}.${subid}.body.text1` })}</div>
                        <div className={st.text}>{intl.formatMessage({ id: `services.${id}.${subid}.body.text2` })}</div>
                        <ul className={st.list}>
                            <li>{intl.formatMessage({ id: `services.${id}.${subid}.body.list1` })}</li>
                            <li>{intl.formatMessage({ id: `services.${id}.${subid}.body.list2` })}</li>
                            <li>{intl.formatMessage({ id: `services.${id}.${subid}.body.list3` })}</li>
                        </ul>
                        <div className={st.picture} data-aos="picture" data-aos-duration="800">
                            <img
                                // @ts-ignore
                                src={require('./images/3.png')}
                                alt={intl.formatMessage({ id: 'home.work.work3.title' })}
                            />
                        </div>
                        <div className={st.text}>{intl.formatMessage({ id: `services.${id}.${subid}.body.text3` })}</div>
                        <blockquote className={st.blockquote}>{intl.formatMessage({ id: `services.${id}.${subid}.body.blockquote` })}</blockquote>
                        <div className={st.text}>{intl.formatMessage({ id: `services.${id}.${subid}.body.text4` })}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Body;
