import React from 'react';
import { useIntl } from 'react-intl';
import st from './Team.module.scss';
// import { ReactComponent as AboutIcon } from '../../../../assets/images/icons/about.svg';
import { ReactComponent as CasesIcon } from '../../../../assets/images/icons/cases.svg';
import { ReactComponent as SolutionIcon } from '../../../../assets/images/icons/solution.svg';
import { ReactComponent as GlobeIcon } from '../../../../assets/images/icons/globe-big.svg';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const Team = () => {
    const intl = useIntl();
    const { mobileIs, tabletIs } = useSelector(({ UIStore }) => UIStore);

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                {!tabletIs && <GlobeIcon className={st.globe} />}
                <div className={classNames('row', st.top)}>
                    {mobileIs && <div className="col-1"></div>}
                    <div className="col-5 col-md-2 col-lg-4 col-xl-6">
                        <div className={st['count-wrapper']}>
                            <p className={st.count}>7</p>
                        </div>
                    </div>
                    {mobileIs && <div className="col-1"></div>}
                    <div className="col-5 col-md-3 col-lg-4 col-xl-4">
                        <div className={st.team__list}>
                            <div className={st.team__item}>
                                Katharina H<span>partner and ceo</span>
                            </div>
                            <div className={st.team__item}>
                                Max B<span>partner and ceo</span>
                            </div>
                            <div className={st.team__item}>
                                Christoph R<span>cmo and partner</span>
                            </div>
                            {/*<div className={st.team__item}>
                                John M <span>partner</span>
                            </div>
                            <div className={st.team__item}>
                                Anthony P<span>cto</span>
                            </div>*/}

                        </div>
                    </div>
                    {mobileIs && <div className="col-1"></div>}
                    <div className="col-5 col-md-2 col-lg-3 col-xl-2">
                        <div className={st.team__list}>
                            <div className={st.team__item}>
                                Nick P<span>art director</span>
                            </div>
                            {/*<div className={st.team__item}>
                                Dina T<span>designer</span>
                            </div>*/}
                            <div className={st.team__item}>
                                Denis K<span>developer</span>
                            </div>
                            {/*<div className={st.team__item}>
                                Paulo L<span>developer</span>
                            </div>*/}
                            {/*<div className={st.team__item}>
                                Demeter B<span>developer</span>
                            </div>*/}
                            <div className={st.team__item}>
                                Sergio F<span>developer</span>
                            </div>
                            <div className={st.team__item}>
                                Cynthia F<span>marketing</span>
                            </div>
                            <div className={st.team__item}>
                                Anna P<span>accountant</span>
                            </div>
                            {/*<div className={st.team__item}>
                                Kirill K<span>developer</span>
                            </div>
                            <div className={st.team__item}>
                                Eugene K<span>developer</span>
                            </div>
                            <div className={st.team__item}>
                                Vladimir C<span>qa</span>
                            </div>*/}
                        </div>
                    </div>
                    {!mobileIs && <div className="col-md-1 col-lg-1 col-xl-3"></div>}
                </div>

                <div className={classNames('row', st.bottom)}>
                    {!tabletIs && (
                        <div className="col-3 col-md-3 col-lg-3 col-xl-4">
                            <h2 className={st.title}>
                                <span className={st.title_1}>{intl.formatMessage({ id: 'about.cases.title1' })}</span>
                                <span className={st.title_2}>{intl.formatMessage({ id: 'about.cases.title2' })}</span>
                                <span className={st.title_2}>{intl.formatMessage({ id: 'about.cases.title3' })}</span>
                                {/*<a href="/#" className={st.title_3}>
                                    {intl.formatMessage({ id: 'about.cases.title3' })}
                                </a>*/}
                            </h2>
                        </div>
                    )}
                    <div className="col-3 col-md-2 col-lg-2 col-xl-2">
                        <Link to="/work" className={st.card}>
                            <div className={st.card__body}>
                                <CasesIcon />
                                {intl.formatMessage({ id: 'home.cases.cases' })}
                            </div>
                        </Link>
                    </div>
                    <div className="col-3 col-md-2 col-lg-2 col-xl-2">
                        <Link to="/services" className={st.card}>
                            <div className={st.card__body}>
                                <SolutionIcon />
                                {intl.formatMessage({ id: 'home.cases.solution' })}
                            </div>
                        </Link>
                    </div>
                    <div className="col-3 col-md-1 col-xl-2"></div>
                    {tabletIs && (
                        <div className="col-3 col-md-3">
                            <h2 className={st.title}>
                                <span className={st.title_1}>{intl.formatMessage({ id: 'home.cases.title' })}</span>
                                <span className={st.title_2}>{intl.formatMessage({ id: 'home.cases.title2' })}</span>
                                <a href="/#" className={st.title_3}>
                                    {intl.formatMessage({ id: 'home.cases.title3' })}
                                </a>
                            </h2>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Team;
