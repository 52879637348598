// @ts-nocheck
import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from 'components/ScrollToTop';
import { Provider } from "react-redux";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={ store }>
        <Router>

            <ScrollToTop />

            <HelmetProvider>
                <App />
            </HelmetProvider>
        </Router>
    </Provider>
);
