import React from 'react';
import { useIntl } from 'react-intl';
import st from './Hero.module.scss';
import { useParams } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';
import { NonAuthRoutes } from 'routes/routes';

const Hero = () => {
    const intl = useIntl();
    const { id, subid } = useParams();

    return (
        <>
            <section className={st.wrapper}>
                <div className={st.container}>
                    <div className="row">
                        <div className="col-2 col-md-3 col-lg-4 col-xl-6"></div>
                        <div className="col-2 col-md-3 col-lg-4 col-xl-4">
                            <h1 className={st.title}>{intl.formatMessage({ id: `services.${id}.${subid}.hero.title` })}</h1>
                        </div>
                    </div>
                </div>
            </section>
            <Breadcrumbs
                links={[
                    {
                        name: 'Home',
                        link: NonAuthRoutes.home,
                    },
                    {
                        name: 'Services',
                        link: NonAuthRoutes.services,
                    },
                    {
                        name: intl.formatMessage({ id: `services.${id}.hero.title` }),
                        link: `${NonAuthRoutes.services}/${id}`,
                    },
                    {
                        name: intl.formatMessage({ id: `services.${id}.${subid}.hero.title` }),
                    },
                ]}
            />
            <section className={st.wrapper_sub}>
                <div className="row">
                    <div className="col-md-3 col-lg-4 col-xl-6" />
                    <div className="col-5 col-md-5 col-lg-6 col-xl-6">
                        <h1 className={st.text}>{intl.formatMessage({ id: `services.${id}.${subid}.hero.text` })}</h1>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Hero;
