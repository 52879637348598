import React from 'react';
import { useIntl } from 'react-intl';
import stTags from '../../components/WithStyledTags/withStyledTags.module.scss';
import st from '../PrivacyPolicy/privacyPolicy.module.scss';
import classNames from 'classnames';
import Header from 'components/Header';
import Footer from 'components/Footer/Footer';

const TermsOfUse = () => {
    const intl = useIntl();

    return (
        <>
            <Header />

            <section className={classNames(st.main)} data-scroll-section>
                <section className={stTags.with_styled_tags}>
                    <div className={st.main_container}>
                        <h1 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'termsOfUse.title' }) }}></h1>
                        <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'termsOfUse.content' }) }}></div>
                    </div>
                </section>
            </section>

            <Footer />
        </>
    );
};

export default TermsOfUse;
