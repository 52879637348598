import React from 'react';
import { useIntl } from 'react-intl';
import st from './About.module.scss';
import { ReactComponent as GlobeIcon } from '../../../../assets/images/icons/globe.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/images/icons/link.svg';
// import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/icons/arrow-right.svg';
import classNames from 'classnames';
// import { NonAuthRoutes } from 'routes/routes';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const About = () => {
    const intl = useIntl();
    const { mobileIs } = useSelector(({ UIStore }) => UIStore);

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                <div className={classNames('row', st.top)}>
                    <div className="col-2 col-md-2 col-lg-1 col-xl-1">
                        <h2 className={st.title}>{intl.formatMessage({ id: 'home.about.title' })}</h2>
                    </div>
                    <div className="d-none d-lg-block col-lg-1 col-xl-1"></div>
                    {!mobileIs && (
                        <>
                        <div className="col-md-2 col-lg-1 col-xl-1">
                            <div className={st.link}>
                                {intl.formatMessage({ id: 'home.about.link' })}
                                {/*<ArrowRightIcon className={st.link__icon} />*/}
                            </div>
                        </div>
                        </>
                    )}
                    <div className="d-none d-lg-block col-md-2 col-lg-1 col-xl-3"></div>
                    <div className="col-3 col-md-2 col-lg-3 col-xl-2">
                        <p className={st.title}>{intl.formatMessage({ id: 'home.about.since' })}</p>
                    </div>
                    <div className={st.dot}></div>
                </div>
                <div className={classNames('row', st.body)}>
                    <div className="col-1 col-md-2 col-lg-1 col-xl-1">
                        <GlobeIcon className={st.globe} />
                    </div>
                    <div className="d-none d-lg-block col-md-1 col-lg-1 col-xl-1"></div>
                    <div className="col-1 col-md-1 col-lg-1 col-xl-1">
                        <p className={st.count}>{intl.formatMessage({ id: 'home.about.count' })}</p>
                    </div>
                    <div className="d-none d-md-block col-md-1 col-lg-1 col-xl-5"></div>
                    <div className="col-4 col-md-4 col-lg col-xl-7">
                        <a href="/about" className={st.studio}>
                            <span className={st.studio__text}>{intl.formatMessage({ id: 'home.about.studio' })}</span>
                            <span className={st.studio__text}>
                                {intl.formatMessage({ id: 'home.about.studio2' })}
                                <LinkIcon className={st.studio__icon} />
                            </span>
                        </a>
                    </div>
                    {/*{mobileIs && (
                        <>
                            <div className="col-1"></div>
                            <div className="col-1 col-md-2 col-lg-1 col-xl-1">
                                <Link to={NonAuthRoutes.about} className={st.link}>
                                    {intl.formatMessage({ id: 'home.about.link' })}
                                    <ArrowRightIcon className={st.link__icon} />
                                </Link>
                            </div>
                        </>
                    )}*/}
                </div>
            </div>
        </section>
    );
};

export default About;
