// @ts-nocheck
export const mediaQuery = {
    mobile: "(max-width: 767px)",
    tablet: "(max-width: 1024px)",
};

export const themeModes = {
    basis: "light",
    alternate: "dark",
};

const initialState = {
    mobileIs: window.matchMedia(mediaQuery.mobile).matches,
    tabletIs: window.matchMedia(mediaQuery.tablet).matches,
    isTouchDevice: ('ontouchstart' in window) || (navigator.maxTouchPoints > 0),
    popupActive: false,
    locale: localStorage.getItem('locale') ?? process.env.REACT_APP_DEFAULT_LOCALE,
    theme: localStorage.getItem('theme') ?? themeModes.basis,
};

export function UIStore(state = initialState, action) {
    switch (action.type) {
        case 'setMobileIs':
            return {
                ...state,
                mobileIs: action.value,
            };

        case 'setTabletIs':
            return {
                ...state,
                tabletIs: action.value,
            };

        case 'setDevices':
            return {
                ...state,
                isTouchDevice: action.value,
            };

        case 'togglePopup':
            return {
                ...state,
                popupActive: action.value,
            };

        case 'togglePreloader':
            return {
                ...state,
                loading: action.value,
            };

        case 'changeLocale':
            const path = window.location.pathname.length > 2 && (window.location.pathname.charAt(3) === '/' || window.location.pathname.charAt(3) === '') ?
                (`${ action.value === 'en' ? "" : `/${ action.value }` }${ window.location.pathname.slice(3) }`) : (`${ action.value === 'en' ? "" : `/${ action.value }` }${ window.location.pathname }`);

            window.history.pushState({}, "", path + (window.location.search ? window.location.search : ""));
            localStorage.setItem('locale', action.value);

            return {
                ...state,
                locale: action.value,
            };

        case 'toggleTheme':
            localStorage.setItem('theme', action.value);

            return {
                ...state,
                theme: action.value,
            };

        default:
            return state;
    }
}
