import React from 'react';
import { useIntl } from 'react-intl';
import stTags from '../../components/WithStyledTags/withStyledTags.module.scss';
import st from './privacyPolicy.module.scss';
import classNames from 'classnames';
import Header from 'components/Header';

const PrivacyPolicy = () => {
    const intl = useIntl();

    function formatHumanDate(date) {
        const day = intl.formatDate(new Date(date), { day: 'numeric' });
        const month = intl.formatDate(new Date(date), { month: 'long' });
        const year = intl.formatDate(new Date(date), { year: 'numeric' });

        return `${month} ${day}, ${year}`;
    }

    return (
        <>
            <Header />

            <section className={classNames(stTags.with_styled_tags, st.main, "noise")} data-scroll-section>
                <div className={st.hero_container}>
                    <h1>{intl.formatMessage({ id: 'privacyPolicy.title' })}</h1>

                    <div className={st.date}>{formatHumanDate(new Date())}</div>
                </div>

                <div className={st.main_container}>
                    <h2 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'privacyPolicy.content.title' }) }}></h2>
                    <h3>Owner of this website</h3>
                    <p>
                        KHMW Media GmbH, <br/>
                        Tuchlauben 7a, 1010 Vienna <br/><br/>

                        +43 6641333558 <br/>
                        hello@brandmybrand.io <br/><br/>

                        Company register number: FN 548009 x, Vienna Commercial Court<br/><br/>

                        UID number: ATU76418459<br/><br/>

                        Trade: <br/>
                        Business organisation <br/>
                        Provision of marketing services, management consultancy <br/>
                        including business organisation, advertising graphic design <br/>
                        Provision of IT services <br/>
                    </p>

                    <h3>Liability for the content of this website</h3>

                    <p>We assume no liability for the topicality, correctness and completeness of the information provided on our website. Liability claims relating to material or immaterial damage caused by the use or non-use of the information provided or by the use of incorrect or incomplete information are fundamentally excluded, unless there is evidence of wilful intent or gross negligence on our part. All offers are subject to change and non-binding. We expressly reserve the right to change, supplement or delete parts of the pages or the entire offer without prior notice or to cease publication temporarily or permanently.
                    </p>

                    <h3>Liability for links to third-party websites</h3>

                    <p>All links have been compiled with the utmost care. However, we accept no liability whatsoever for the accuracy, content or freedom from aggressive and/or harmful content, such as viruses, Trojans, worms, etc., of these websites. In particular, we accept no responsibility for the content of external websites referred to by hyperlinks, for which the respective provider is solely liable. Links to our website are welcome if they are designed as external links (own browser window). A transfer of the main window into a frame of the link setter is not permitted. If a website to which we have linked contains illegal content, please inform us and the link will be removed immediately.
                    </p>

                    <h3>Copyright</h3>

                    <p>We always endeavour to respect the copyrights of others or to use our own or licence-free works. Our content and works on this website are subject to copyright. Third-party contributions are labelled as such. Duplication, processing, distribution and any kind of utilisation outside the limits of copyright law require the written consent of the respective author or creator. When you use this website, various personal data is collected. Personal data is data that can be used to identify you personally. This privacy policy explains what data we collect and what we use it for. It also explains how and for what purpose this is done. We would like to point out that data transmission over the Internet (e.g. when communicating by email) may be subject to security vulnerabilities. Complete protection of data against access by third parties is not possible.
                    </p>

                    <h2>General terms and conditions</h2>
                    <h3>1. Confirmation of the booking</h3>
                    <p>KHMW Media GmbH considers the booking to be binding as soon as this has been clearly communicated by e-mail or verbally. From this point in time, the deposit is due and must be paid in any case. An order confirmation and receipt of payment shall finalise the order for both parties. The offer is valid until 2 weeks after submission of the offer.
                    </p>

                    <h3>2. Graphic rights and development rights</h3>
                    <p>Until full payment of the order, all rights to the work remain with the contractor. The right to use and reproduce the graphics supplied shall be held exclusively by the client. The right to use the work is unrestricted in terms of location and time. The client is not authorised to make changes to the graphics.
                    </p>

                    <h3>3. Personal rights</h3>
                    <p>By placing the order, the client automatically agrees that KHMW Media GmbH may use all resulting graphics for portfolio purposes (e.g. portfolio books, the company's own website/blog, partner websites and printed magazines).
                    </p>

                    <h3>4. Cancellation</h3>
                    <p>KHMW GmbH reserves the right to withdraw this offer prior to final commissioning (written or verbal confirmation) without giving reasons. A maximum period of 10 days prior to the order date shall apply for cancellation by the client. If this deadline is not met, 10% of the total amount must be paid.
                    </p>

                    <h3>5. Remuneration for work</h3>
                    <p>If the contractual partner withdraws from the execution of the order placed for unspecified reasons, KHMW GmbH shall be entitled to half of the fee plus all ancillary costs actually incurred, unless otherwise agreed. If a meeting with KHMW GmbH is cancelled by the client within 24 hours, the current hourly rate for the planned expenditure will be charged.
                    </p>

                    <h3>6. Payment</h3>
                    <p>In the absence of any other express written agreement, a payment on account amounting to 50% of the anticipated invoice total shall be made when the order is placed. Unless expressly agreed otherwise in writing, the remaining fee shall be due for payment immediately after invoicing. If a payment term is agreed, the invoices submitted shall be due for payment within 14 days of the invoice date. Invoices are payable without any deductions and free of charges. In the case of remittance (postal order, bank or postal savings bank transfer, etc.), payment shall only be deemed to have been made upon the Designer's notification of receipt of payment. The risk of the postal route of legal submissions (lawsuits, applications for execution) shall be borne by the contractual partner. If the contractual partner (client) refuses acceptance due to defective fulfilment or asserts warranty claims, the fee shall nevertheless be due for payment. In the event of default in payment by the contractual partner, Sheepnsons GmbH is entitled to charge interest on arrears at an annual rate of 10% above the base interest rate, without prejudice to any claims for damages in excess of this.
                    </p>

                    <h3>7. Privacy policy</h3>
                    <p>The protection of your personal data is of particular concern to KHMW Media GmbH. KHMW GmbH therefore processes your data exclusively on the basis of the statutory provisions (GDPR, TKG 2003). Project-related information is handled exclusively by KHMW Media GmbH and is not passed on to any other party unless the customer requests it. This project-related data will be treated confidentially by KHMW Media GmbH, in the event of cooperation, it will be kept for at least 7 years, should no cooperation arise, KHMW Media GmbH will keep this data stored in my e-mail programme for at least 2 years.
                    </p>
                </div>
            </section>
        </>
    );
};

export default PrivacyPolicy;
