import React, { useEffect } from 'react';
import './settings/axios';
import './assets/styles/index.scss';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import ErrorPage from './pages/ErrorPage';
import { Helmet } from 'react-helmet-async';
// import UIStore from './store/UIStore';
import { IntlProvider } from 'react-intl';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsOfUse from 'pages/TermsOfUse';
import English from './lang/en.json';
import { detectBrowserHover } from './helpers/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { mediaQuery } from 'store/reducers/UIStore';
import WorksPage from 'pages/Works';
import WorkPage from 'pages/Work';
import AboutPage from 'pages/About';
import GetInTouchPage from 'pages/GetInTouch';
import Preloader from 'components/Preloader';
import DrawLine from 'components/DrawLine/DrawLine';
import ServicesPage from 'pages/Services';
import ServicesCategoryPage from 'pages/ServicesCategory';
import ServicePage from 'pages/Service';
import BlogsPage from 'pages/Blogs';
import BlogPage from 'pages/Blog';

const App = () => {
    const { locale, theme } = useSelector(({ UIStore }) => UIStore);
    const dispatch = useDispatch();
    const [i18nStrings, setI18nStrings] = React.useState(English);

    useEffect(() => {
        const path = window.location.pathname.length > 2 && (window.location.pathname.charAt(3) === '/' || window.location.pathname.charAt(3) === '') ? window.location.pathname.slice(1, 3) : 'en';
        const mediaMobile = window.matchMedia(mediaQuery.mobile);
        const mediaTablet = window.matchMedia(mediaQuery.tablet);

        dispatch({ type: 'changeLocale', value: path });
        detectBrowserHover();

        const handleMobile = (query) => {
            dispatch({ type: 'setMobileIs', value: query.matches });
        };

        const handleTablet = (query) => {
            dispatch({ type: 'setTabletIs', value: query.matches });
        };

        handleMobile(mediaMobile);
        handleTablet(mediaTablet);

        mediaMobile.addEventListener('change', handleMobile);
        mediaTablet.addEventListener('change', handleTablet);

        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--vh', `${ window.innerHeight }px`);
        };

        window.addEventListener('resize', appHeight);
        window.addEventListener('resize', detectBrowserHover);

        appHeight();

        return () => {
            window.removeEventListener('resize', appHeight);
            window.removeEventListener('resize', detectBrowserHover);
            mediaMobile.removeEventListener('change', handleMobile);
            mediaTablet.removeEventListener('change', handleTablet);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function handleResize() {
            dispatch({ type: 'setDevices', value: (Boolean('ontouchstart' in window || navigator.maxTouchPoints > 0)) });
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    });

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            setI18nStrings(English);
            document.documentElement.setAttribute('lang', "en");
        }
        return () => { isMounted = false };
    }, [locale]);

    return (
        <IntlProvider
            // @ts-ignore
            messages={ i18nStrings }
            locale={ locale }
            defaultLocale="en"
        >
            <Helmet>
                <title>BrandMyBrand - loading</title>
            </Helmet>

            <div id={theme} className='theme-root'>
                <Preloader />

                <DrawLine />

                <Routes>
                    <Route path='/'>
                        <Route index element={<Home />} />
                        <Route path='blog' element={<BlogsPage />} />
                        <Route path='blog/:id' element={<BlogPage />} />
                        <Route path='work' element={<WorksPage />} />
                        <Route path='work/:id' element={<WorkPage />} />
                        <Route path='about' element={<AboutPage />} />
                        <Route path='services' element={<ServicesPage />} />
                        <Route path='services/:id' element={<ServicesCategoryPage />} />
                        <Route path='services/:id/:subid' element={<ServicePage />} />
                        <Route path='get-in-touch' element={<GetInTouchPage />} />
                        <Route path='privacy' element={<PrivacyPolicy />} />
                        <Route path='terms' element={<TermsOfUse />} />
                    </Route>
                    <Route path='/:locale'>
                        <Route index element={<Home />} />
                        <Route path='blog' element={<BlogsPage />} />
                        <Route path='blog/:id' element={<BlogPage />} />
                        <Route path='work' element={<WorksPage />} />
                        <Route path='work/:id' element={<WorkPage />} />
                        <Route path='services' element={<ServicesPage />} />
                        <Route path='services/:id' element={<ServicesCategoryPage />} />
                        <Route path='services/:id/:subid' element={<ServicePage />} />
                        <Route path='about' element={<AboutPage />} />
                        <Route path='get-in-touch' element={<GetInTouchPage />} />
                        <Route path='privacy' element={<PrivacyPolicy />} />
                        <Route path='terms' element={<TermsOfUse />} />
                    </Route>
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
            </div>
        </IntlProvider>
    );
};

export default App;
