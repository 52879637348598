// @ts-nocheck
import React from 'react';
import st from './blog.module.scss';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { NonAuthRoutes } from 'routes/routes';
import { ReactComponent as ArrowRightIcon } from '../../assets/images/icons/arrow-right.svg';
import { Link } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';

export const BlogPage = () => {
    const intl = useIntl();
    const { id } = useParams();
    const { mobileIs } = useSelector(({ UIStore }) => UIStore);

    return (
        <>
            <Header />

            <main className={st.main}>
                <section className={st.hero}>
                    <div className={st.container}>
                        <div className="row">
                            {!mobileIs && <div className="col-2 col-md-3 col-lg-4 col-xl-6"></div>}
                            <div className="col-6 col-md-5 col-lg-8 col-xl-9">
                                <h1 className={st.title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `blog.${id}.title` }) }} />
                                <p className={st.date} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `blog.${id}.date` }) }} />
                            </div>
                        </div>
                    </div>
                </section>

                <section className={st.wrapper}>
                    <div className={st.container}>
                        <div className={classNames('row', st.body)}>
                            <div className={"col-6 col-md-8 col-lg-4 col-xl-6"}>
                                <div className={st.body__left}>
                                    <div className={classNames(st.picture, st.picture_mobile)} data-aos="picture" data-aos-duration="800">
                                        <img
                                            // @ts-ignore
                                            src={require('./images/3.png')}
                                            alt={intl.formatMessage({ id: 'home.work.work3.title' })}
                                        />
                                    </div>
                                    <div className={st.text__left}>Here's a list of 110 companies with permanent, seasonal, or trial four-day workweeks. We've also included links to their remote jobs and employee benefits.</div>


                                    <div className={st.user}>
                                        <div className={st.user__image}>
                                            <img
                                                // @ts-ignore
                                                src={require('./images/3.png')}
                                                alt={intl.formatMessage({ id: 'home.work.work3.title' })}
                                            />
                                        </div>
                                        <div className={st.user__body}>
                                            <p className={st.user__name}>Alexandr Konstantinov</p>
                                            <p className={st.user__position}>Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames("col-6 col-md-8 col-lg col-xl-9", st.content)}>
                                <div className={st.body_desktop}>
                                    <div className={st.picture} data-aos="picture" data-aos-duration="800">
                                        <img
                                            // @ts-ignore
                                            src={require('./images/3.png')}
                                            alt={intl.formatMessage({ id: 'home.work.work3.title' })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={st.wrapper}>
                    <div className={st.container}>
                        <div className={classNames('row', st.body)}>
                            <div className="col-6 col-md-8 col-lg-4 col-xl-6">
                                <h2 className={st.subtitle}>How to Answer «Why Do You Want to Work Here?» (Sample Answers Included)</h2>
                            </div>
                            <div className={classNames("col-6 col-md-8 col-lg col-xl-9", st.content)}>
                                <div className={st.text}>{intl.formatMessage({ id: `blog.${id}.text1` })}</div>
                                <div className={st.text}>{intl.formatMessage({ id: `blog.${id}.text2` })}</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={st.wrapper}>
                    <div className={st.container}>
                        <div className={classNames('row', st.body)}>
                            <div className="col-6 col-md-8 col-lg-4 col-xl-6">
                                <h2 className={st.subtitle}>24 companies trialing a 4-day workweekAdvanced RV</h2>
                            </div>
                            <div className={classNames("col-6 col-md-8 col-lg col-xl-9", st.content)}>
                                <div className={st.text}>{intl.formatMessage({ id: `blog.${id}.text2` })}</div>
                                <ul className={st.list}>
                                    <li>{intl.formatMessage({ id: `blog.${id}.list1` })}</li>
                                    <li>{intl.formatMessage({ id: `blog.${id}.list2` })}</li>
                                    <li>{intl.formatMessage({ id: `blog.${id}.list3` })}</li>
                                </ul>
                                <div className={st.picture} data-aos="picture" data-aos-duration="800">
                                    <img
                                        // @ts-ignore
                                        src={require('./images/3.png')}
                                        alt={intl.formatMessage({ id: 'home.work.work3.title' })}
                                    />
                                </div>
                                <div className={st.text}>{intl.formatMessage({ id: `blog.${id}.text3` })}</div>
                                <blockquote className={st.blockquote}>{intl.formatMessage({ id: `blog.${id}.blockquote` })}</blockquote>
                                <div className={st.text}>{intl.formatMessage({ id: `blog.${id}.text4` })}</div>
                                <div className={st.text}>{intl.formatMessage({ id: `blog.${id}.text5` })}</div>
                            </div>
                        </div>
                    </div>
                </section>

                <Breadcrumbs
                    links={[
                        {
                            name: 'Home',
                            link: NonAuthRoutes.home,
                        },
                        {
                            name: 'Blog',
                            link: NonAuthRoutes.blog,
                        },
                        {
                            name: 'Blog name',
                        },
                    ]}
                />
            </main>

            <section className={st.info_other}>
                <div className={classNames(st.container, st.container_other)}>
                    <div className={classNames('row', st.info__top)}>
                        <div className="col-2 col-md-2 col-lg-1 col-xl-1">
                            <h2 className={st.info__title}>{intl.formatMessage({ id: `blog.${id}.next.last` })}</h2>
                        </div>
                        {!mobileIs && (
                            <>
                                <div className="col-md-1 col-lg-1 col-xl-1"></div>
                                <div className="col-md-1 col-lg-1 col-xl-1">
                                    <Link to={`${NonAuthRoutes.work}/arthromed`} className={st.info__link}>
                                        {intl.formatMessage({ id: `blog.${id}.next.link` })}
                                        <ArrowRightIcon className={st.info__link__icon} />
                                    </Link>
                                </div>
                            </>
                        )}
                        <div className="col-1 col-md-1 col-lg-1 col-xl-3"></div>
                        <div className="col-1 col-md-1 col-lg col-xl-1">
                            <p className={st.info__title}>{intl.formatMessage({ id: `blog.${id}.next.number` })}</p>
                        </div>
                        <div className={st.info__dot}></div>
                    </div>
                    <div className={classNames('row', st.info__body)}>
                        <div className="col-1 col-md-2 col-lg-1 col-xl-1">
                            <p className={st.info__name}>{intl.formatMessage({ id: `blog.${id}.next.next` })}</p>
                        </div>
                        <div className="col-2 col-md-3 col-lg-3 col-xl-5"></div>
                        <div className="col-3 col-md-3 col-lg col-xl-7">
                            <div
                                className={st.info__subname}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `blog.${id}.next.name` }) }}
                            />
                        </div>
                    </div>
                    <div className={classNames('row', st.info__bottom)}>
                        <div className="col-6">
                            <div className={classNames(st.pictures__image, st.pictures__image_big, st.pictures__image_large, st.pictures__image_next)}>
                                {<img src={require(`./images/${id}/next.jpg`)} alt="" />}
                            </div>
                        </div>
                    </div>
                    {mobileIs && (
                        <>
                            <div className="col-1 col-md-1 col-lg-1 col-xl-1"></div>
                            <div className="col-2 col-md-1 col-lg-1 col-xl-1">
                                <Link to={`${NonAuthRoutes.work}/arthromed`} className={st.info__link}>
                                    {intl.formatMessage({ id: `blog.${id}.next.link` })}
                                    <ArrowRightIcon className={st.info__link__icon} />
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </section>

            <Footer bgColor="#31333F" />
        </>
    );
};
