// @ts-nocheck
import React from 'react';
import st from './about.module.scss';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Team from './components/Team/Team';
// import Service from './components/Service/Service';
// import Expertise from './components/Expertise/Expertise';
// import Cases from './components/Cases/Cases';

export const AboutPage = () => {
    return (
        <>
            <Header />

            <main className={st.main}>
                <Hero />
                <About />
                <Team />
                {/*<Service />*/}
                {/*<Expertise />*/}
                {/*<Cases />*/}
            </main>

            <Footer bgColor="#31333F" />
        </>
    );
};
