import React from 'react';
import st from './ErrorPage.module.scss';
import Header from 'components/Header';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';

const ErrorPage = () => {
    const intl = useIntl();

    return (
        <>
            <Header />
            <section className={st.hero}>
                <div className={classNames(st.hero__container, st.body)}>
                    <div className={st.text}>{intl.formatMessage({ id: 'app.errorPage.text' })}</div>
                    {/*<div className={st.logo}>
                        <img src="/images/404.png" alt="" srcSet="/images/404@2x.png 2x" />
                    </div>*/}
                    {/*<Link to='/' className={classNames(st.link, 'btn')}>{intl.formatMessage({ id: 'app.errorPage.button' })}</Link>*/}
                    <div className={st.title}>{intl.formatMessage({ id: 'app.errorPage.title' })}</div>
                </div>
            </section>
        </>
    );
};

export default ErrorPage;
