// @ts-nocheck
import React from 'react';
import st from './works.module.scss';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Work from './components/Work/Work';

export const WorksPage = () => {
    return (
        <>
            <Header />

            <main className={st.main}>
                <Hero />
                <Work />
            </main>

            <Footer bgColor="#31333F" />
        </>
    );
};
