import React from 'react';
import { useIntl } from 'react-intl';
import st from './Testimonials.module.scss';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/icons/arrow-right.svg';
import classNames from 'classnames';
import { NonAuthRoutes } from 'routes/routes';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Testimonials = () => {
    const intl = useIntl();
    const { mobileIs } = useSelector(({ UIStore }) => UIStore);

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                <div className={classNames('row', st.top)}>
                    <div className="col-1 col-md-1 col-lg-1 col-xl-1">
                        <h2 className={st.title}>{intl.formatMessage({ id: 'services.testimonials.title' })}</h2>
                    </div>
                    {!mobileIs && (
                        <>
                            <div className="col-md-1 col-lg-1 col-xl-1"></div>
                            <div className="col-md-1 col-lg-1 col-xl-1">
                                <Link to={NonAuthRoutes.work} className={st.link}>
                                    {intl.formatMessage({ id: 'services.testimonials.link' })}
                                    <ArrowRightIcon className={st.link__icon} />
                                </Link>
                            </div>
                        </>
                    )}
                    <div className="col-1 col-md-1 col-lg-1 col-xl-3"></div>
                    <div className="col-1 col-md-1 col-lg col-xl-1">
                        <p className={st.title}>{intl.formatMessage({ id: 'services.testimonials.since' })}</p>
                    </div>
                </div>
                <div className={classNames('row', st.body)}>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-4">
                        <p className={st.work} data-aos="fade-up">{intl.formatMessage({ id: 'services.testimonials.work' })}</p>
                    </div>
                    <div className="d-none d-lg-block col-lg-2 col-xl-2"></div>
                    <div className="col-6 col-md-4 col-lg-6 col-xl-6">
                        <p className={st.text} data-aos="fade-up" data-aos-anchor-placement="bottom-center">{intl.formatMessage({ id: 'services.testimonials.text' })}</p>
                        <div className={st.author}>
                            <div className={st.author__avatar}></div>
                            <div className={st.author__body}>
                                <p className={st.author__name} data-aos="fade-up" data-aos-anchor-placement="bottom-center">{intl.formatMessage({ id: 'services.testimonials.author.name' })}</p>
                                <div className={st.author__position} data-aos="fade-up" data-aos-anchor-placement="bottom-center">{intl.formatMessage({ id: 'services.testimonials.author.position' })}</div>
                            </div>
                        </div>
                    </div>
                    {mobileIs && (
                        <>
                            <div className="col col-md-1 col-lg-1 col-xl-1"></div>
                            <div className="col-auto col-md-1 col-lg-1 col-xl-1">
                                <Link to={NonAuthRoutes.work} className={st.link}>
                                    {intl.formatMessage({ id: 'services.testimonials.link' })}
                                    <ArrowRightIcon className={st.link__icon} />
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
