import { LocaleMap } from "../types";

const availableLocales = Object.keys(LocaleMap).join('|');

const NonAuthRoutes = {
    home: "/",
    homeLocale: `/(${availableLocales})/`,

    work: "/work",
    workLocale: `/:locale(${availableLocales})/work`,

    blog: "/blog",
    blogLocale: `/:locale(${availableLocales})/blog`,

    services: "/services",
    servicesLocale: `/:locale(${availableLocales})/services`,

    getInTouch: "/get-in-touch",
    getInTouchLocale: `/:locale(${availableLocales})/get-in-touch`,

    about: "/about",
    aboutLocale: `/:locale(${availableLocales})/about`,

    privacy: "/privacy",
    privacyLocale: `/:locale(${availableLocales})/privacy`,

    terms: "/terms",
    termsLocale: `/:locale(${availableLocales})/terms`,
}

export { NonAuthRoutes };
