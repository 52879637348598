import React from 'react';
import { useIntl } from 'react-intl';
import st from './Work.module.scss';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/icons/arrow-right.svg';
import classNames from 'classnames';
import { NonAuthRoutes } from 'routes/routes';
import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';

const Work = () => {
    const intl = useIntl();
    // const { mobileIs } = useSelector(({ UIStore }) => UIStore);

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                <div className={classNames('row', st.top)}>
                    <div className="col-2 col-md-2 col-lg-2 col-xl-2">
                        <h2 className={st.title}>{intl.formatMessage({ id: 'home.work.title' })}</h2>
                    </div>
                            
                    <div className="col-md-1 col-lg-3 col-xl-1">
                        <Link to={NonAuthRoutes.work} className={st.link}>
                            {intl.formatMessage({ id: 'home.work.link' })}
                            <ArrowRightIcon className={st.link__icon} />
                        </Link>
                    </div>
                    
                    {/*<div className="col-1 col-md-1 col-lg-1 col-xl-1">
                        <p className={st.title}>{intl.formatMessage({ id: 'home.work.since' })}</p>
                    </div>*/}

                    <div className={st.dot}></div>
                </div>
                <div className={st.body}>
                    <Link to={`/work/vipsfirst`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.vipsfirst.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.vipsfirst.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('./images/vipsfirst/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.vipsfirst.top.title' })}
                            />
                        </div>
                    </Link>
                    <Link to={`/work/k2`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.k2.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.k2.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require(`./images/k2/hero.jpg`)}
                                alt={intl.formatMessage({ id: 'works.k2.top.title' })}
                            />
                        </div>
                    </Link>
                    <Link to={`/work/fadorealestate`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.fadorealestate.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.fadorealestate.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require('./images/fadorealestate/hero.jpg')}
                                alt={intl.formatMessage({ id: 'works.fadorealestate.top.title' })}
                            />
                        </div>
                    </Link>
                    <Link to={`/work/arthromed`} className={st.card}>
                        <div className={st.card__name}>
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.arthromed.top.title' }) }} />
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'works.arthromed.top.subtitle' }) }} />
                        </div>
                        <div className={st.card__image}>
                            <img
                                // @ts-ignore
                                src={require(`./images/arthromed/hero.jpg`)}
                                alt={intl.formatMessage({ id: 'works.arthromed.top.title' })}
                            />
                        </div>
                    </Link>
                </div>
            </div>
        </section >
    );
};

export default Work;
