// @ts-nocheck
import React from 'react';
import st from './ServicesCategory.module.scss';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Service from './components/Service/Service';
import Work from 'pages/Home/components/Work/Work';
import Testimonials from 'pages/Services/components/Testimonials/Testimonials';
import Breadcrumbs from 'components/Breadcrumbs';
import { NonAuthRoutes } from 'routes/routes';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

export const ServicesCategoryPage = () => {
    const intl = useIntl();
    const { id } = useParams();

    return (
        <>
            <Header />

            <main className={st.main}>
                <Hero />
                <Service />
                <Work />
                <Testimonials />
                <Breadcrumbs
                    links={[
                        {
                            name: 'Home',
                            link: NonAuthRoutes.home,
                        },
                        {
                            name: 'Services',
                            link: NonAuthRoutes.services,
                        },
                        {
                            name: intl.formatMessage({ id: `services.${id}.hero.title` }),
                        },
                    ]}
                />
            </main>

            <Footer bgColor="#31333F" />
        </>
    );
};
