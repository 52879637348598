import React from 'react';
import { useIntl } from 'react-intl';
import st from './Hero.module.scss';

const Hero = () => {
    const intl = useIntl();

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                <div className="row">
                    <div className="col-2 col-md-3 col-lg-4 col-xl-6"></div>
                    <div className="col-2 col-md-3 col-lg-4 col-xl-4">
                        <h1 className={st.title}>{intl.formatMessage({ id: 'works.hero.title' })}</h1>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
