import React from 'react';
import { useIntl } from 'react-intl';
import st from './Hero.module.scss';

const Hero = () => {
    const intl = useIntl();

    return (
        <section className={st.wrapper}>
            <div className={st.container}>
                <div className="row">
                    <div className="d-none d-md-block col-md-3 col-lg-4 col-xl-6"></div>
                    <div className="col">
                        <h1 className={st.title}>{intl.formatMessage({ id: 'about.hero.title' })}</h1>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
